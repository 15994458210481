<div
  style="
    height: 100vh;
    width: 100%;
    background-color: black;
    background-image: url(../../../assets/images/background.png);
  "
>
  <div class="opacity-holder"></div>
</div>

<div class="nsignup heading">
  <div class="headingholder-title">
    <span class="headingholder1">Pay</span>
    <span class="headingholder2">Day</span>
    <span class="headingholder3">Portal</span>
    <span class="headingholder4">.com</span>
  </div>
  <div class="headingholder-logo">
    <span class="logo" translate>login.PoweredBy</span>
    <span> <img src="assets/images/logo.png" /></span>
  </div>
  <div class="rightsreserved">
    <span translate>login.Copyright</span>
  </div>
</div>

<form [formGroup]="form" (ngSubmit)="signUp()" class="nsignup">
  <div class="centralized">
    <div class="transparentframe">
      <div class="signin" translate>signUp.signUp</div>
      <div class="registeredonly">
        ({{ "signUp.registrationOnly" | translate }})
      </div>
      <div style="margin-top: 20px">
        <ul class="list-unstyled">
          <li>
            <div class="ui-inputgroup">
              <span class="ui-inputgroup-addon form-input-icon">
                <i class="far fa-user"></i>
              </span>
              <input autocomplete="new"
                     id="firstName"
                     type="text"
                     pInputText
                     placeholder="{{ 'signUp.firstName' | translate }}"
                     formControlName="firstName"
                     class="form-input"
                     style="height: 50px" />
            </div>
            <div class="form-error" style="margin-left: 65px">
              <div id="firstNameValidation"
                   *ngIf="
                  !form.controls['firstName'].valid &&
                  form.controls['firstName'].dirty"
                   translate>
                signUp.firstNameRequired
              </div>
            </div>
          </li>
          <li>
            <div class="ui-inputgroup">
              <span class="ui-inputgroup-addon form-input-icon">
                <i class="far fa-user"></i>
              </span>
              <input autocomplete="new"
                     id="lastName"
                     type="text"
                     pInputText
                     placeholder="{{ 'signUp.lastName' | translate }}"
                     formControlName="lastName"
                     class="form-input"
                     style="height: 50px" />
            </div>
            <div class="form-error" style="margin-left: 65px">
              <div id="lastNameValidation"
                   *ngIf="
                  !form.controls['lastName'].valid &&
                  form.controls['lastName'].dirty"
                   translate>
                signUp.lastNameRequired
              </div>
            </div>
          </li>
          <li>
            <div class="ui-inputgroup">
              <span class="ui-inputgroup-addon form-input-icon">
                <i class="far fa-user"></i>
              </span>
              <input autocomplete="new"
                     id="user"
                     type="text"
                     pInputText
                     placeholder="{{ 'signUp.username' | translate }}"
                     formControlName="user"
                     class="form-input"
                     style="height: 50px" />
            </div>
            <div class="form-error" style="margin-left: 65px">
              <div id="userValidation"
                   *ngIf="
                  !form.controls['user'].valid && form.controls['user'].dirty"
                   translate>
                signUp.usernameRequired
              </div>
            </div>
          </li>
          <li>
            <div class="ui-inputgroup">
              <span class="ui-inputgroup-addon form-input-icon">
                <i class="fas fa-key"></i>
              </span>
              <input autocomplete="new-password"
                     id="password"
                     type="password"
                     pPassword
                     [feedback]="false"
                     placeholder="{{ 'signUp.password' | translate }}"
                     formControlName="password"
                     class="form-input"
                     style="height: 50px" />
            </div>
            <div class="form-error" style="margin-left: 65px">
              <div id="passwordValidation"
                   *ngIf="
                  !form.controls['password'].valid &&
                  form.controls['password'].dirty"
                   translate>
                signUp.passwordRequired
              </div>
            </div>
          </li>
          <li>
            <div class="ui-inputgroup">
              <span class="ui-inputgroup-addon form-input-icon">
                <i class="fas fa-key"></i>
              </span>
              <input autocomplete="new-password"
                     id="confirmPass"
                     type="password"
                     pPassword
                     [feedback]="false"
                     placeholder="{{ 'signUp.confirmPass' | translate }}"
                     formControlName="confirmPass"
                     class="form-input"
                     style="height: 50px" />
            </div>
            <div class="form-error" style="margin-left: 65px">
              <div id="confirmPassValidation"
                   *ngIf="!form.controls['confirmPass'].valid"
                   translate>
                signUp.confirmPassRequired
              </div>
            </div>
          </li>
          <li>
            <div class="ui-inputgroup">
              <span class="ui-inputgroup-addon form-input-icon">
                <i class="fas fa-calculator"></i>
              </span>
              <input autocomplete="verification-code"
                     id="verificationCode"
                     type="text"
                     pInputText
                     placeholder="{{ 'signUp.verificationCode' | translate }}"
                     formControlName="verificationCode"
                     class="form-input"
                     style="height: 50px" />
            </div>
            <div class="form-error" style="margin-left: 65px">
              <div id="verificationCodeValidation"
                   *ngIf="!form.controls['verificationCode'].valid && form.controls['verificationCode'].dirty"
                   translate>
                signUp.verificationCodeRequired
              </div>
            </div>
          </li>
          <!--<li>
    <div class="ui-inputgroup">
      <span class="ui-inputgroup-addon form-input-icon"
        ><i class="fas fa-calendar"></i
      ></span>
      <p-calendar
        id="dateOfBirth"
        [readonlyInput]="true"
        [monthNavigator]="true"
        [yearNavigator]="true"
        [yearRange]="yearRange"
        placeholder="{{ 'signUp.dateOfBirth' | translate }}"
        formControlName="dateOfBirth"
        class="dateofbirth"
        style="height: 50px"
      ></p-calendar>
    </div>
    <div class="form-error">
      <div
        id="dateOfBirthValidation"
        *ngIf="
          !form.controls['dateOfBirth'].valid &&
          form.controls['dateOfBirth'].dirty
        "
        translate
      >
        signUp.dateOfBirthRequired
      </div>
    </div>
  </li>-->
          <!--<li>
    <div class="ui-inputgroup">
      <span
        class="ui-inputgroup-addon form-input-icon"
        style="margin-top: 3px"
        ><i class="fas fa-question-circle"></i
      ></span>
      <p-dropdown
        formControlName="employeeIdentifierType"
        [options]="employeeIdentifierTypes"
        class="form-input"
        style="margin-left: -10px"
      >
        <ng-template let-item pTemplate="selectedItem">
          <span
            style="
              vertical-align: middle;
              color: black;
              line-height: 38px;
            "
            >{{ item.label }}</span
          >
        </ng-template>
        <ng-template let-type pTemplate="item">
          <div
            class="ui-helper-clearfix"
            style="position: relative; height: 25px"
          >
            <div
              style="
                font-size: 14px;
                float: left;
                margin-top: 4px;
                line-height: 50px;
              "
            >
              {{ type.label }}
            </div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="form-error">
      <div
        id="employeeIdentifierTypeNoValidation"
        *ngIf="
          !form.controls['employeeIdentifierType'].valid &&
          form.controls['employeeIdentifierType'].dirty
        "
        translate
      >
        signUp.employeeIdentifierTypeRequired
      </div>
    </div>
  </li>-->
          <!--<li
    style="height: 55px"
    *ngIf="
      SelectedEmployeeIdentifierType ==
      EmployeeIdentifierTypesEnum.EmployeeID
    "
  >
    <div class="ui-inputgroup">
      <span class="ui-inputgroup-addon form-input-icon"
        ><i class="fas fa-id-badge"></i
      ></span>
      <input
        id="employeeIdentifier"
        pInputText
        type="text"
        placeholder="{{ 'signUp.employeeIdentifier' | translate }}"
        formControlName="employeeIdentifier"
        class="form-input"
        style="height: 50px"
      />
    </div>
    <div class="form-error">
      <div
        id="employeeIdentifierValidation"
        *ngIf="
          !form.controls['employeeIdentifier'].valid &&
          form.controls['employeeIdentifier'].dirty
        "
        translate
      >
        signUp.employeeIdentifierRequired
      </div>
    </div>
  </li>-->
          <!--<li
    style="height: 55px"
    *ngIf="
      SelectedEmployeeIdentifierType ==
        EmployeeIdentifierTypesEnum.PhoneNumber ||
      !SelectedEmployeeIdentifierType
    "
  >
    <div class="ui-inputgroup">
      <span class="ui-inputgroup-addon form-input-icon"
        ><i class="fas fa-mobile-alt"></i
      ></span>
      <input
        autocomplete="new"
        id="mobileNo"
        pInputText
        type="text"
        placeholder="{{ 'signUp.mobileNo' | translate }}"
        phoneMask
        formControlName="mobileNo"
        class="form-input"
        style="height: 50px"
      />
    </div>
    <div class="form-error">
      <div
        id="mobileNoValidation"
        *ngIf="
          !form.controls['mobileNo'].valid &&
          form.controls['mobileNo'].dirty
        "
        translate
      >
        signUp.mobileNoRequired
      </div>
    </div>
  </li>-->
          <li style="height: 45px">
            <div style="display: flex; justify-content: center">
              <p-checkbox id="termsAndConditions"
                          [binary]="true"
                          formControlName="termsAndConditions"></p-checkbox>
              <span for="termsAndConditions"
                    style="
                  font-size: 12px;
                  color: white;
                  padding: 10px;
                  width: 305px;
                ">
                {{ "signUp.acceptTermsAndConditions" | translate }}
                <span style="cursor: pointer; color: #80eacf"
                      (click)="openTerms()">
                  "{{
                    "signUp.acceptTermsAndConditionsClickable" | translate
                  }}"
                </span>
              </span>
            </div>
          </li>
          <li class="ui-inputgroup" style="margin-top: 30px">
            <button class="button"
                    id="signin"
                    pButton
                    type="submit"
                    label="{{ 'signUp.signUp' | translate }}"
                    [disabled]="!form.valid || progress"></button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</form>

<p-dialog
  [(visible)]="confirmationDialogVisible"
  header="{{ 'signUp.confirmation' | translate }}"
  modal="true"
  [closable]="true"
  class="signup"
>
  {{ "signUp.confirmationMessage" | translate }}
  <p-footer>
    <button
      type="button"
      pButton
      icon="pi pi-check"
      (click)="closeConfirmationDialog()"
      label="{{ 'general.ok' | translate }}"
    ></button>
  </p-footer>
</p-dialog>
