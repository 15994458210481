import { FormProperty } from './formProperty';
import { PropertyTypes } from '../enum/propertyTypes';
import { EventEmitter } from '@angular/core';
import { GSInputDecimalControl } from '../../controls/gsControls';

export class FormNumberDecimalProperty extends FormProperty {
  type = PropertyTypes.Number;
  changed = new EventEmitter<any>();
  keydown = new EventEmitter<any>();
  onpaste = new EventEmitter<any>();
  minFractionDigits: number;
  maxFractionDigits: number;
  allowEmpty: boolean;
  component = GSInputDecimalControl;

  constructor(name: string, label: string, minFractionDigits: number, maxFractionDigits: number, row?: number, column?: number,
        rowspan?: number, columnspan?: number, validate?: boolean, allowEmpty?: boolean) {
        super(name, label, row , column ,
          rowspan, columnspan, validate);
    this.minFractionDigits = minFractionDigits;
    this.maxFractionDigits = maxFractionDigits;
  }
}
