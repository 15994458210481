import { inject } from '@angular/core';

//import { Session, Notification, NotificationType, Command } from "../model/model";
import { CacheService } from 'ng2-cache';
import {
  Session,
  Action,
  Notification,
  NotificationType,
} from '../entity/entities';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { WebStorageUtility } from '../utils/tools';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { saveAs } from 'file-saver';
import { environment } from '../../../environments/environment';
import { SvcClients } from './SvcClients';
import { DatePipe } from '@angular/common';
import { Guid } from '../utils/utils';
import { BroadcastService } from './broadcast.service';

//@Component({ providers: [CacheService] })
//@Injectable({
//    providedIn: 'root'
//}),
export class BaseClient {
  private static headers: HttpHeaders =
    new HttpHeaders(/*{ 'Content-Type': 'application/json; charset=utf-8' }*/);
  private static tokenHeaderInitialized = false;
  //private spinnerSemafor: number = 0;

  private broadcastService = inject(BroadcastService);
  private http = inject(HttpClient);
  private cacheService = inject(CacheService);
  private _router = inject(Router);
  public datepipe = inject(DatePipe);

  constructor() {
    if (!BaseClient.headers.has('Content-Type'))
      BaseClient.headers = BaseClient.headers.append(
        'Content-Type',
        'application/json'
      );
  }

  private initTokenHeader(requestId) {
    if (!BaseClient.tokenHeaderInitialized && Session && Session.token) {
      BaseClient.headers = BaseClient.headers.append(
        'GratSync-Token',
        Session.token
      );
      if (!BaseClient.headers.has('GratSync-Portal'))
        BaseClient.headers = BaseClient.headers.append(
          'GratSync-Portal',
          environment.portalCode
        );
      BaseClient.tokenHeaderInitialized = true;
    } else if (!BaseClient.headers.has('GratSync-Portal'))
      BaseClient.headers = BaseClient.headers.append(
        'GratSync-Portal',
        environment.portalCode
      );

    if (requestId) {
      if (!BaseClient.headers.has('GratSync-RequestId'))
        BaseClient.headers = BaseClient.headers.append(
          'GratSync-RequestId',
          requestId
        );
      else
        BaseClient.headers = BaseClient.headers.set(
          'GratSync-RequestId',
          requestId
        );
    }
  }

  public reinitTokenHeader() {
    if (Session && Session.token) {
      if (BaseClient.headers.keys().some((k) => k == 'GratSync-Token')) {
        BaseClient.headers = BaseClient.headers.set(
          'GratSync-Token',
          Session.token
        );
        if (!BaseClient.headers.has('GratSync-Portal'))
          BaseClient.headers = BaseClient.headers.append(
            'GratSync-Portal',
            environment.portalCode
          );
      } else {
        BaseClient.headers = BaseClient.headers.append(
          'GratSync-Token',
          Session.token
        );
        if (!BaseClient.headers.has('GratSync-Portal'))
          BaseClient.headers = BaseClient.headers.append(
            'GratSync-Portal',
            environment.portalCode
          );
      }
      BaseClient.tokenHeaderInitialized = true;
    }
  }

  public get(
    svcClient: SvcClients,
    route: string,
    message?: string,
    action?: Action,
    forceWebApiCall: boolean = true,
    storeCacheKey: string = null,
    supressErrorNotification: boolean = false,
    requestIdOverride: string = null,
    params = null
  ) {
    return new Promise<any>((success, failure) => {
      if (!forceWebApiCall && storeCacheKey) {
        var res = this.tryGetFromCache(storeCacheKey, action);
        if (res) {
          let data = { result: res };
          success(data);
          return;
        }
      }

      //let notification: Notification = new Notification(this.translate.instant('general.PleaseWait'), message, NotificationType.Info);
      //Session.notifications.add(notification.id, notification);
      //this.spinnerSemafor++;
      //if (message)
      // this.spinner.show();
      let requestId = requestIdOverride
        ? requestIdOverride
        : Guid.newGuid().toString();
      this.initTokenHeader(requestId);

      this.http
        .get(this.resolveBaseUrl(svcClient) + route, {
          headers: BaseClient.headers,
          params: params,
        })
        .subscribe(
          (result) => {
            //if (--this.spinnerSemafor<=0)
            //  this.spinner.hide();
            if (storeCacheKey) this.cacheService.set(storeCacheKey, result);
            let data = { result: result, requestId: requestId };
            success(data);
            //Session.notifications.remove(notification.id);

            if (action) {
              action.complete();
              action.unselect();
            }
          },
          (error) => {
            //this.spinner.hide();
            //Session.notifications.remove(notification.id);
            // if (!supressErrorNotification) {
            //   let errorNotification: Notification = new Notification(
            //     error.statusText,
            //     error.error
            //       ? error.error.Message
            //         ? error.error.Message
            //         : error.error
            //       : '',
            //     NotificationType.Error,
            //     10000
            //   );
            //   Session.notifications.add(
            //     errorNotification.id,
            //     errorNotification
            //   );
            // }
            if (
              error.status == 401 &&
              error?.error?.Message === 'Invalid token.'
            ) {
              this.logOut();
            }
            failure(error);
            //Session.notifications.remove(notification.id);

            if (action) {
              action.fault(error.statusText);
              action.unselect();
            }
          }
        );
    });
  }

  public post(
    svcClient: SvcClients,
    route: string,
    body: Object,
    message?: string,
    action?: Action,
    forceWebApiCall: boolean = true,
    storeCacheKey: string = null,
    supressErrorNotification: boolean = false,
    requestId?
  ) {
    return new Promise<any>((success, failure) => {
      if (!forceWebApiCall && storeCacheKey) {
        var res = this.tryGetFromCache(storeCacheKey, action);
        if (res) {
          let data = { result: res };
          success(data);
          return;
        }
        return;
      }

      //let notification: Notification = new Notification(this.translate.instant('general.PleaseWait'), message, NotificationType.Info);
      //Session.notifications.add(notification.id, notification);
      //this.spinnerSemafor++;
      //this.spinner.show();
      requestId =
        requestId == undefined ? Guid.newGuid().toString() : requestId;
      this.initTokenHeader(requestId);
      this.http
        .post(this.resolveBaseUrl(svcClient) + route, JSON.stringify(body), {
          headers: BaseClient.headers,
        })
        .subscribe(
          (result) => {
            //if (--this.spinnerSemafor <= 0)
            //  this.spinner.hide();
            if (storeCacheKey) this.cacheService.set(storeCacheKey, result);

            let data = { result: result, requestId: requestId };
            success(data);
            //Session.notifications.remove(notification.id);
            if (action) {
              action.complete();
              action.unselect();
            }
          },
          (error) => {
            //if (--this.spinnerSemafor <= 0)
            //  this.spinner.hide();
            //Session.notifications.remove(notification.id);
            // if (!supressErrorNotification) {
            //   let errorNotification: Notification = new Notification(
            //     error.statusText == 'OK' ? '' : error.statusText,
            //     error.error
            //       ? error.error.Message
            //         ? error.error.Message
            //         : error.error
            //       : '',
            //     NotificationType.Error,
            //     10000
            //   );
            //   Session.notifications.add(
            //     errorNotification.id,
            //     errorNotification
            //   );
            // }

            if (error.status == 401) {
              this.logOut();
            }
            if (failure != null) failure(error);
            //Session.notifications.remove(notification.id);

            if (action) {
              action.fault(error.statusText);
              action.unselect();
            }
          }
        );
    });
  }

  public postFile(
    svcClient: SvcClients,
    route: string,
    body: Object,
    message?: string,
    action?: Action,
    forceWebApiCall: boolean = true,
    storeCacheKey: string = null,
    supressErrorNotification: boolean = false,
    requestId?
  ) {



    return new Promise<any>((success, failure) => {
      if (!forceWebApiCall && storeCacheKey) {
        var res = this.tryGetFromCache(storeCacheKey, action);
        if (res) {
          let data = { result: res };
          success(data);
          return;
        }
        return;
      }

      requestId = requestId ?? Guid.newGuid().toString();

      this.initTokenHeader(requestId);

      let fileHeaders;

      if (BaseClient.headers.keys().some((k) => k == 'Content-Type')) {
        fileHeaders = BaseClient.headers.delete(
          'Content-Type'
        );
      }

      this.http
        .post(
          this.resolveBaseUrl(svcClient) + route,
          body,
          {
            headers: fileHeaders,
          }
        )
        .subscribe(
          (result) => {
            if (storeCacheKey) this.cacheService.set(storeCacheKey, result);

            let data = { result: result, requestId: requestId };
            if (BaseClient.headers.keys().some((k) => k == 'Content-Type')) {
              BaseClient.headers = BaseClient.headers.set(
                'Content-Type',
                'application/json'
              )
            }

            success(data);
            if (action) {
              action.complete();
              action.unselect();
            }
          },
          (error) => {
            if (error.status == 401) {
              this.logOut();
            }
            if (failure != null)
              failure(error);

            if (action) {
              action.fault(error.statusText);
              action.unselect();
            }
          }
        );
    });
  }

  public getraw(
    route: string,
    message?: string,
    action?: Action,
    supressErrorNotification: boolean = false
  ) {
    return new Promise<any>((success, failure) => {
      //let notification: Notification = new Notification(this.translate.instant('general.PleaseWait'), message, NotificationType.Info);
      //Session.notifications.add(notification.id, notification);
      //this.spinnerSemafor++;
      //if (message)
      // this.spinner.show();
      let requestId = Guid.newGuid().toString();
      this.initTokenHeader(requestId);

      this.http.get(route, { headers: BaseClient.headers }).subscribe(
        (result) => {
          //if (--this.spinnerSemafor<=0)
          //  this.spinner.hide();

          let data = { result: result, requestId: requestId };
          success(data);
          //Session.notifications.remove(notification.id);

          if (action) {
            action.complete();
            action.unselect();
          }
        },
        (error) => {
          //this.spinner.hide();
          //Session.notifications.remove(notification.id);
          // if (!supressErrorNotification) {
          //   let errorNotification: Notification = new Notification(
          //     error.statusText,
          //     error.error
          //       ? error.error.Message
          //         ? error.error.Message
          //         : error.error
          //       : '',
          //     NotificationType.Error,
          //     10000
          //   );
          //   Session.notifications.add(errorNotification.id, errorNotification);
          // }
          if (error.status == 401) {
            this.logOut();
          }
          failure(error);
          //Session.notifications.remove(notification.id);

          if (action) {
            action.fault(error.statusText);
            action.unselect();
          }
        }
      );
    });
  }

  public postraw(
    route: string,
    body: Object,
    message: string,
    action?: Action,
    supressErrorNotification: boolean = false
  ) {
    return new Promise<any>((success, failure) => {
      //let notification: Notification = new Notification(this.translate.instant('general.PleaseWait'), message, NotificationType.Info);
      //Session.notifications.add(notification.id, notification);
      //this.spinnerSemafor++;
      //this.spinner.show();
      //this.initTokenHeader();
      this.http.post(route, JSON.stringify(body)).subscribe(
        (result) => {
          //if (--this.spinnerSemafor <= 0)
          //  this.spinner.hide();

          let data = { result: result };
          success(data);
          //Session.notifications.remove(notification.id);
          if (action) {
            action.complete();
            action.unselect();
          }
        },
        (error) => {
          //if (--this.spinnerSemafor <= 0)
          //  this.spinner.hide();
          //Session.notifications.remove(notification.id);
          // if (!supressErrorNotification) {
          //   let errorNotification: Notification = new Notification(
          //     error.statusText,
          //     error.error
          //       ? error.error.Message
          //         ? error.error.Message
          //         : error.error
          //       : '',
          //     NotificationType.Error,
          //     10000
          //   );
          //   Session.notifications.add(errorNotification.id, errorNotification);
          // }

          if (error.status == 401) {
            this.logOut();
          }
          if (failure != null) failure(error);
          //Session.notifications.remove(notification.id);

          if (action) {
            action.fault(error.statusText);
            action.unselect();
          }
        }
      );
    });
  }

  public put(
    svcClient: SvcClients,
    route: string,
    body: Object,
    message: string,
    action?: Action,
    url: string = 'apiUrl',
    requestId?
  ) {
    return new Promise<any>((success, failure) => {
      //let notification: Notification = new Notification(this.translate.instant('general.PleaseWait'), message, NotificationType.Info);
      //Session.notifications.add(notification.id, notification);
      requestId =
        requestId == undefined ? Guid.newGuid().toString() : requestId;
      this.initTokenHeader(requestId);
      //this.spinnerSemafor++;
      //this.spinner.show();
      this.http
        .put(this.resolveBaseUrl(svcClient) + route, JSON.stringify(body), {
          headers: BaseClient.headers,
        })
        .subscribe(
          (result) => {
            //if (--this.spinnerSemafor <= 0)
            //  this.spinner.hide();
            let data = { result: result, requestId: requestId };
            success(data);
            //Session.notifications.remove(notification.id);

            if (action) {
              action.complete();
              action.unselect();
            }
          },
          (error) => {
            //if (--this.spinnerSemafor <= 0)
            //  this.spinner.hide();
            //Session.notifications.remove(notification.id);
            // let errorNotification: Notification = new Notification(
            //   error.statusText,
            //   error.error
            //     ? error.error.Message
            //       ? error.error.Message
            //       : error.error
            //     : '',
            //   NotificationType.Error,
            //   10000
            // );
            // Session.notifications.add(errorNotification.id, errorNotification);

            if (error.status == 401) {
              this.logOut();
            }

            if (failure != null) failure(error);
            //Session.notifications.remove(notification.id);

            if (action) {
              action.fault(error.statusText);
              action.unselect();
            }
          }
        );
    });
  }

  public delete(
    svcClient: SvcClients,
    route: string,
    message?: string,
    action?: Action,
    forceWebApiCall: boolean = true,
    storeCacheKey: string = null,
    supressErrorNotification: boolean = false,
    requestIdOverride?
  ) {
    return new Promise<any>((success, failure) => {
      if (!forceWebApiCall && storeCacheKey) {
        var res = this.tryGetFromCache(storeCacheKey, action);
        if (res) {
          let data = { result: res };
          success(data);
          return;
        }
      }

      //let notification: Notification = new Notification(this.translate.instant('general.PleaseWait'), message, NotificationType.Info);
      //Session.notifications.add(notification.id, notification);
      //this.spinnerSemafor++;
      //if (message)
      // this.spinner.show();
      let requestId =
        requestIdOverride == undefined
          ? Guid.newGuid().toString()
          : requestIdOverride;
      this.initTokenHeader(requestId);

      this.http
        .delete(this.resolveBaseUrl(svcClient) + route, {
          headers: BaseClient.headers,
        })
        .subscribe(
          (result) => {
            //if (--this.spinnerSemafor<=0)
            //  this.spinner.hide();
            if (storeCacheKey) this.cacheService.set(storeCacheKey, result);

            let data = { result: result, requestId: requestId };
            success(data);
            //Session.notifications.remove(notification.id);

            if (action) {
              action.complete();
              action.unselect();
            }
          },
          (error) => {
            //this.spinner.hide();
            // Session.notifications.remove(notification.id);
            // if (!supressErrorNotification) {
            //   let errorNotification: Notification = new Notification(
            //     error.statusText,
            //     error.error
            //       ? error.error.Message
            //         ? error.error.Message
            //         : error.error
            //       : '',
            //     NotificationType.Error,
            //     10000
            //   );
            //   Session.notifications.add(
            //     errorNotification.id,
            //     errorNotification
            //   );
            // }
            if (error.status == 401) {
              this.logOut();
            }
            failure(error);
            // Session.notifications.remove(notification.id);

            if (action) {
              action.fault(error.statusText);
              action.unselect();
            }
          }
        );
    });
  }

  public downloadFile(
    svcClient: SvcClients,
    url: string,
    fileName?,
    requestId?
  ) {
    return new Promise<any>((resolve) => {
      requestId =
        requestId == undefined ? Guid.newGuid().toString() : requestId;
      this.initTokenHeader(requestId);

      this.downloadLink(this.resolveBaseUrl(svcClient) + url).subscribe(
        (response: HttpResponse<Blob>) => {
          fileName = fileName ? fileName : 'file';
          const contentDisposition = response.headers.get(
            'content-disposition'
          );
          if (contentDisposition) {
            const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = fileNameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              if (matches[1].startsWith('utf-8')) {
                matches[1] = decodeURIComponent(
                  matches[1].replace("utf-8''", '')
                );
              }
              fileName = matches[1].replace(/['"]/g, '');
            }
          }
          saveAs(response.body, fileName);
          resolve(null);
        },
        (error) => {
          if (error.status == 401) {
            this.logOut();
          }
          //else if (error.status == 404) {
          //  let errorNotification: Notification = new Notification(
          //    error.statusText,
          //    `File not found`,
          //    NotificationType.Warning,
          //    10000
          //  );
          //  Session.notifications.add(errorNotification.id, errorNotification);
          //}
          //else {
          //  let errorNotification: Notification = new Notification(
          //    error.statusText,
          //    error.error
          //      ? error.error.Message
          //        ? error.error.Message
          //        : error.error
          //      : '',
          //    NotificationType.Error,
          //    10000
          //  );
          //  Session.notifications.add(errorNotification.id, errorNotification);
          //}
        }
      );
    });
  }

  public downloadFilePost(
    svcClient: SvcClients,
    url: string,
    model,
    fileName?
  ) {
    return new Promise<any>((resolve) => {
      this.downloadLinkPost(
        this.resolveBaseUrl(svcClient) + url,
        model
      ).subscribe(
        (response: HttpResponse<Blob>) => {
          fileName = fileName ? fileName : 'file';
          const contentDisposition = response.headers.get(
            'content-disposition'
          );
          if (contentDisposition) {
            const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = fileNameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              if (matches[1].startsWith('utf-8')) {
                matches[1] = decodeURIComponent(
                  matches[1].replace("utf-8''", '')
                );
              }
              fileName = matches[1].replace(/['"]/g, '');
            }
          }
          saveAs(response.body, fileName);
          resolve(null);
        },
        (error) => {
          if (error.status == 401) {
            this.logOut();
          }
        }
      );
    });
  }

  public downloadFileBase(url: string) {
    window.location.href = url;
  }

  public downloadPayrollExportFile(
    svcClient: SvcClients,
    url: string,
    fileName?,
    requestId?
  ) {
    return new Promise<any>((success, failure) => {
      requestId =
        requestId == undefined ? Guid.newGuid().toString() : requestId;
      this.initTokenHeader(requestId);

      this.downloadLink(this.resolveBaseUrl(svcClient) + url).subscribe(
        (response: HttpResponse<Blob>) => {
          if (response.status == HttpStatusCode.Created) {
            let data = { isDirty: true };
            success(data);
            return;
          }

          fileName = fileName ? fileName : 'file';
          const contentDisposition = response.headers.get(
            'content-disposition'
          );
          if (contentDisposition) {
            const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = fileNameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              if (matches[1].startsWith('utf-8')) {
                matches[1] = decodeURIComponent(
                  matches[1].replace("utf-8''", '')
                );
              }
              fileName = matches[1].replace(/['"]/g, '');
            }
          }
          saveAs(response.body, fileName);
          success(null);
        },
        (error) => {
          if (error.status == 401) {
            this.logOut();
          } else {
            let errorNotification: Notification = new Notification(
              error.statusText,
              error.error
                ? error.error.Message
                  ? error.error.Message
                  : error.error
                : '',
              NotificationType.Error,
              10000
            );
            Session.notifications.add(errorNotification.id, errorNotification);
          }
        }
      );
    });
  }

  public openFile(svcClient: SvcClients, url: string) {
    this.downloadLink(this.resolveBaseUrl(svcClient) + url).subscribe(
      (response: HttpResponse<Blob>) => {
        let fileName = 'file';
        let contentDisposition = response.headers.get('content-disposition');
        if (contentDisposition) {
          let fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          let matches = fileNameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            if (matches[1].startsWith('utf-8')) {
              matches[1] = decodeURIComponent(
                matches[1].replace("utf-8''", '')
              );
            }
            fileName = matches[1].replace(/['"]/g, '');
          }
        }
        let contentType = response.headers.get('content-type');
        let type = '';
        if (contentType) type = contentType;
        let blob: any = new Blob([response.body], { type: type });

        //// For other browsers:
        //// Create a link pointing to the ObjectURL containing the blob.
        //const data = window.URL.createObjectURL(blob);
        //var link = document.createElement('a');
        //link.href = data;
        //link.download = fileName;
        //link.click();
        //setTimeout(function () {
        //  // For Firefox it is necessary to delay revoking the ObjectURL
        //  window.URL.revokeObjectURL(data);
        //}, 100);

        const url = window.URL.createObjectURL(blob);
        window.open(url);
      }
    );
  }

  private downloadLink(url): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(url, {
      headers: BaseClient.headers,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  private downloadLinkPost(url, model): Observable<HttpResponse<Blob>> {
    return this.http.post<Blob>(url, model, {
      headers: BaseClient.headers,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  private tryGetFromCache(cacheKey: string, action?: Action): any {
    if (this.cacheService.exists(cacheKey)) {
      if (action) {
        action.complete();
        action.unselect();
      }
      return this.cacheService.get(cacheKey);
    }
  }
  private mapFileType(fileExtension: string) {
    var fileType;
    if (fileExtension == '.txt') {
      fileType = 'text/plain';
    }
    if (fileExtension == '.pdf') {
      fileType = 'application/pdf';
    }
    if (fileExtension == '.doc') {
      fileType = 'application/vnd.ms-word';
    }
    if (fileExtension == '.docx') {
      fileType = 'application/vnd.ms-word';
    }
    if (fileExtension == '.xls') {
      fileType = 'application/vnd.ms-excel';
    }
    if (fileExtension == '.png') {
      fileType = 'image/png';
    }
    if (fileExtension == '.jpg') {
      fileType = 'image/jpeg';
    }
    if (fileExtension == '.jpeg') {
      fileType = 'image/jpeg';
    }
    if (fileExtension == '.gif') {
      fileType = 'image/gif';
    }
    if (fileExtension == '.csv') {
      fileType = 'text/csv';
    }
    return fileType;
  }

  resolveBaseUrl(svcClient: SvcClients) {
    switch (svcClient) {
      case SvcClients.AdminSvc:
        return environment.adminSvcUrl;

      case SvcClients.ResourceSvc:
        return environment.resourceSvcUrl;

      case SvcClients.DwollaSvc:
        return environment.dwollaSvcUrl;
      //case SvcClients.CrmProxyApi:
      //  return environment.crmProxyApiUrl;

      default:
        return undefined;
    }
  }

  private logOut() {
    WebStorageUtility.remove(localStorage, 'grat_auth');
    Session.clear();
    this._router.navigate(['/login']);
    this.broadcastService.disconnect();
  }
}
