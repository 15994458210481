import { DataValue } from '../decorators/decorators';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { Tools } from '../utils/tools';
import { SignalR } from 'ng2-signalr';
import { Guid } from '../utils/utils';
import { TableColumn } from './table/tableColumn';
import { FormProperty } from './form/formProperty';

export class Dictionary<N> {
  [key: string]: any;
  remove(key: string) {
    delete this[key];
  }
  contains(key: string) {
    return !(this[key] == undefined);
  }
  public clear() {
    Object.getOwnPropertyNames(this).forEach((key) => {
      delete this[key];
    });
  }
}

export class GSSubject {
  private _subject: Subject<any>;
  constructor() {
    this._subject = new Subject();
  }

  get subject() {
    return this._subject;
  }

  public subscriptions = [];

  destroy$ = new Subject<void>();

  public subscribe(method, subscriber) {
    if (
      !this._subject.observers.some(
        (d) => (<any>d).destination._next == method
      ) &&
      !this.subscriptions.some((s) => s.method == method)
    ) {
      this._subject.pipe(takeUntil(this.destroy$)).subscribe(method);
      this.subscriptions.push({ subscriber: subscriber, method: method });
    }
  }

  public next(value?) {
    this._subject.next(value);
  }

  public complete() {
    this._subject.complete();
  }

  public unsubscribe(method) {
    // if (
    //   this._subject.observers.some((d) => (<any>d).destination._next == method)
    // ) {
    //   let observer = this._subject.observers.find(
    //     (d) => (<any>d).destination._next == method
    //   );
    //   this._subject.observers.splice(
    //     this._subject.observers.indexOf(observer),
    //     1
    //   );
    //   //this._subject.unsubscribe();
    //   {
    //     this.destroy$.next();
    //     this.destroy$.complete();
    //   }
    // }
    if (this.subscriptions.some((s) => s.method == method)) {
      let subscription = this.subscriptions.find((s) => s.method == method);
      this.subscriptions.splice(this.subscriptions.indexOf(subscription), 1);
      this.destroy$.next();
    }
  }

  public unsubscribeSubscriber(subscriber) {
    for (let i = this.subscriptions.length - 1; i >= 0; i--) {
      if (this.subscriptions[i].subscriber == subscriber) {
        if (
          this._subject.observers.some(
            (d) => (<any>d).destination._next == this.subscriptions[i].method
          )
        ) {
          let observer = this._subject.observers.find(
            (d) => (<any>d).destination._next == this.subscriptions[i].method
          );
          this._subject.observers.splice(
            this._subject.observers.indexOf(observer),
            1
          );
        }
        this.subscriptions.splice(i, 1);
      }
    }
  }

  public unsubscribeAll() {
    for (let i = this.subscriptions.length - 1; i >= 0; i--) {
      //if (this.subscriptions[i].subscriber == subscriber) {
      if (
        this._subject.observers.some(
          (d) => (<any>d).destination._next == this.subscriptions[i].method
        )
      ) {
        let observer = this._subject.observers.find(
          (d) => (<any>d).destination._next == this.subscriptions[i].method
        );
        this._subject.observers.splice(
          this._subject.observers.indexOf(observer),
          1
        );
      }
      this.subscriptions.splice(i, 1);

      // }
    }
  }
}

export class EventCollection<T> {
  public keys = [];
  public values = [];
  private _select;
  get selected(): T {
    return this.values.find((v) => v.selected);
  }
  public selecteds = [];

  public onSelected = new GSSubject();
  public onUnselected = new GSSubject();
  public onAdded = new GSSubject();
  public onRemoved = new GSSubject();
  onClearItems$ = new BehaviorSubject(null);

  public add(key: string, value: T) {
    this.keys.push(key.toString());
    this.values.push(value);
    this.onAdded.next(value);
  }

  public length() : number {
    return this.keys.length;
  }

  public addItemTrigger(value) {
    this.onAdded.next(value);
  }

  public select(key: string) {
    let selected = this.get(key);
    if (selected) this._select = true;
    this.onSelected.next(selected);
  }

  public unselect(key: string) {
    let selected = this.get(key);
    if (selected) this._select = false;
    this.onUnselected.next(selected);
  }

  public unselectAll() {
    this.values.forEach((value) => {
      if (value.selected) value._select = false;
      this.onUnselected.next(value);
    });
  }

  public remove(key: string, prevetnSelect?) {
    let index = this.keys.indexOf(key);
    if (index != -1) {
      let value = this.values[index];
      this.keys.splice(index, 1);
      this.values.splice(index, 1);
      this.onRemoved.next(value);
      if (this.values[0] && !prevetnSelect) this.values[0].select();
    }
  }

  public removeByIndex(index: number, prevetnSelect?) {
    if (index != -1) {
      let value = this.values[index];
      this.keys.splice(index, 1);
      this.values.splice(index, 1);
      this.onRemoved.next(value);
      if (this.values[0] && !prevetnSelect) this.values[0].select();
    }
  }

  public get(key: string): T {
    var value = this.values[this.keys.indexOf(key)];
    return value;
  }

  public contains(key: string): boolean {
    return this.keys.indexOf(key) > -1;
  }

  public clear() {
    this.keys = [];
    this.values = [];
    this.onClearItems$.next(null);
  }
}

export class UIElement {
  private _data;
  private originalData;
  private _key;

  public readonly: boolean = false;

  onSetItem$ = new BehaviorSubject(null);
  onRenderFinish$ = new BehaviorSubject<void>(null);

  public get data() {
    return this._data;
  }
  public key;

  public get original() {
    return this.originalData;
  }

  public set data(value) {
    this._data = value; // --JSON.parse(JSON.stringify(value));
    this.originalData = JSON.parse(JSON.stringify(value));
    this.dirty = false;
    this.onSetData.next(this);
  }

  public updateData(data) {
    this._data = data;
    this.onSetData.next(this);
  }

  public parameters: Dictionary<any> = new Dictionary<any>();

  @DataValue('ID')
  public id: number;
  @DataValue('Title')
  public title;
  @DataValue('Description')
  public description;
  @DataValue('Code')
  public code: string;
  @DataValue('Icon')
  public icon;
  @DataValue('Name')
  public name;
  public component;
  public route: string;
  public selected: boolean = false;
  private dirty: boolean = false;
  public validationInfo: ValidationInfo[] = [];
  private _list;

  get validOwn() {
    return this.validationInfo.length == 0
      ? true
      : !this.validationInfo.some((vi) => !vi.valid);
  }

  public get isValid() {
    let res =
      this.validationInfo.length == 0
        ? true
        : !this.validationInfo.some((vi) => !vi.valid);

    if (res && this.parameters['isValid'] != undefined)
      res = this.parameters['isValid'];
    if (res)
      for (let i = 0; i < this.items.values.length; i++) {
        if (this.items.values[i].validOwn) res = this.items.values[i].isValid;
        else {
          res = false;
          break;
        }
        if (res == false) break;
      }

    return res;
  }

  public get dirtyOwn() {
    return this.dirty;
  }

  public get isDirty() {
    let res = this.dirty;
    if (!res)
      for (let i = 0; i < this.items.values.length; i++) {
        if (!this.items.values[i].dirtyOwn) res = this.items.values[i].isDirty;
        else {
          res = true;
          break;
        }
        if (res == true) break;
      }
    return res;
  }

  get list(): EventCollection<UIElement> {
    return this._list;
  }
  set list(value: EventCollection<UIElement>) {
    this._list = value;
  }

  setData(val) {
    this._data = val;
  }

  public status;

  public items: EventCollection<Item>;
  public onSelect = new GSSubject();
  public onUnselected = new GSSubject();
  public onRevertData = new GSSubject();
  public onSetData = new GSSubject();
  public onReValidate = new GSSubject();
  public onReload = new GSSubject();

  constructor(collection, data?) {
    if (data) {
      this.data = data;
      this.originalData = JSON.parse(JSON.stringify(data));
    }
    this._list = collection;
    this.items = new EventCollection<Item>();
  }
  public select(params?) {
    if (this.list && this.list instanceof EventCollection)
      this.list.values.forEach((el) => el.unselect(params));

    this.selected = true;
    this.onSelect.next(this);
    if (this.list && this.list instanceof EventCollection)
      this.list.onSelected.next(this);
  }

  public unselect(params?) {
    this.selected = false;
    this.onUnselected.next(this);
    if (this.list instanceof EventCollection) this.list.onUnselected.next(this);
  }

  public revertData(deep = false) {
    if (this.originalData) {
      this.data = JSON.parse(JSON.stringify(this.originalData));
      this.onRevertData.next(this);
      this.dirty = false;
      if (deep) this.items.values.forEach((i) => i.revertData(true));
    }
  }

  public forceDirty(dirty: boolean) {
    this.dirty = dirty;
  }

  //public revertData() {

  //  this.revertItemData(this);
  //}

  //public revertItemData(item) {

  //  if (item.originalData) {
  //    item.data = JSON.parse(JSON.stringify(item.originalData));
  //    item.onRevertData.next(item);
  //    item.dirty = false;
  //  }
  //  item.items.values.forEach(i => this.revertItemData(i));
  //}

  public acceptChanges() {
    this.originalData = JSON.parse(JSON.stringify(this.data));
    this.dirty = false;
    if (this.items.values.length)
      this.items.values.forEach((i) => i.acceptChanges());
  }

  public reCheckDirty() {
    this.dirty = this.checkDirty(this.originalData, this.data, this);
  }

  public reValidate(skip?: boolean, forceValidation?: boolean) {
    this.onReValidate.next({
      item: this,
      skip: skip,
      forceValidation: forceValidation,
    });
  }

  public setDataTrigger() {
    this.onSetData.next({ item: this });
  }

  checkDirty(original, current, item: UIElement) {
    let dirty = false;

    if (item.readonly) return false;

    if (item.items?.values?.some((i) => i.dirty)) return true;

    if (!original) {
      if (current) return true;
      return false;
    }

    if (!(original instanceof Object)) {
      return original == current;
    }

    if (original instanceof Array)
      if (original.length != current.length) return true;

    let props = Object.getOwnPropertyNames(original);
    let currProps = Object.getOwnPropertyNames(current);

    if (props.length != currProps.length) return true;

    for (let i = 0; i < props.length; i++) {
      if (
        !(original[props[i]] instanceof Object) &&
        !(original[props[i]] instanceof Date) &&
        !(current[props[i]] instanceof Date)
      )
        dirty = dirty || original[props[i]] != current[props[i]];
      else if (
        original[props[i]] instanceof Date ||
        current[props[i]] instanceof Date
      )
        dirty =
          dirty ||
          new Date(current[props[i]]).getTime() !=
            new Date(original[props[i]]).getTime();

      if (dirty) break;

      if (!original[props[i]]) {
        if (current[props[i]]) dirty = dirty || true;
        dirty = dirty || false;
      } else {
        if (!dirty && original[props[i]] instanceof Object)
          dirty =
            dirty ||
            this.checkDirty(original[props[i]], current[props[i]], item);
      }
    }

    return dirty;
  }

  public reload() {
    this.onReload.next(this);
  }

  public setItem(data, key?, skipDirtyCheck?: boolean, skipSubscribers?: boolean) {
    let item = new Item(this.items, data);
    let k = key ? key : Guid.newGuid().toString();
    item.key = k;
    this.items.add(k, item);
    if (!skipDirtyCheck === true)
      this.reCheckDirty();
    if (!skipSubscribers === true)
      this.onSetItem$.next(item);
    return item;
  }

  public unsubscribeSubscriber(subscriber) {
    if (subscriber) {
      this.onSelect.unsubscribeSubscriber(subscriber);
      this.onUnselected.unsubscribeSubscriber(subscriber);
      this.onRevertData.unsubscribeSubscriber(subscriber);
      this.onSetData.unsubscribeSubscriber(subscriber);
      this.onReValidate.unsubscribeSubscriber(subscriber);
      this.onReload.unsubscribeSubscriber(subscriber);
    } else {
      this.onSelect.unsubscribeAll();
      this.onUnselected.unsubscribeAll();
      this.onRevertData.unsubscribeAll();
      this.onSetData.unsubscribeAll();
      this.onReValidate.unsubscribeAll();
      this.onReload.unsubscribeAll();
    }

    this.items.values.forEach((item) => item.unsubscribeSubscriber(subscriber));
  }

  public first() {
    if (this.items?.values.length) return this.items?.values[0];
  }

  public rebind(model) {
    let data = Session.getByCode(model, this.code);
    if (data) {
      this.data.IsVisible = data.IsVisible;
      this.data.IsDisabled = data.IsDisabled;
      this.data.Title =
        data.Title != null && data.Title != undefined
          ? data.Title
          : this.data.Title;
    } else this.data.IsVisible = false;
  }

  public static create(parent, data) {}
}

export class Action extends UIElement {
  public method;
  public onCompleted = new GSSubject();
  public onFault = new GSSubject();

  private lastFault;

  public complete() {
    this.onCompleted.next(this);
  }

  public fault(fault) {
    this.lastFault = fault;
    this.onFault.next(this);
  }
}

export class Item extends UIElement {
  public checked: boolean = false;
  public visible: boolean = true;
  public guid = Guid.newGuid();
  constructor(collection, data) {
    super(collection, data);
  }

  public static handleChildItem(data, collection, identity, parent: Item) {
    if (data instanceof Array) {
      data.forEach((child) => {
        var item = parent.items.get(`${child[identity]}`);
        if (item) item.data = child;
        else parent.setItem(child, `${child[identity]}`);
      });
    } else
      data[collection].forEach((child) => {
        let key =
          child[identity] == undefined ? Session.counter-- : child[identity];
        var item = parent.items.get(`${key}`);
        if (item) {
          item.data = child;
        } else parent.setItem(child, `${key}`);
      });
  }
}

export class Menu extends UIElement {
  public menuItems: EventCollection<MenuItem>;

  constructor(collection, data) {
    super(collection, data);
    this.menuItems = new EventCollection<MenuItem>();
  }

  rebind(model) {
    this.menuItems.values.forEach((el) => {
      el.rebind(model);
    });
  }
  public static create(data) {
    Session.menu = new Menu(null, data);
    data
      .sort((a, b) => a.OrderNumber && a.OrderNumber - b.OrderNumber)
      .forEach((el) => {
        MenuItem.create(Session.menu, el);
      });
  }
}

export class MenuItem extends UIElement {
  public pages: EventCollection<Page>;

  constructor(collection, data) {
    super(collection, data);
    this.pages = new EventCollection<Page>();
  }

  rebind(model) {
    // this.pages.values.filter(p=>p.data.IsMultiLocation).forEach(p=>{
    //   p.rebind(model);
    // })

    this.pages.values
      .filter((p) => !p.data.IsMultiLocation)
      .forEach((p) => {
        p.rebind(model);
      });
  }

  public static create(parent, data) {
    let mi = new MenuItem(parent.menuItems, data);
    mi.route = data.Route;
    if (data.Pages)
      data.Pages.forEach((p) => {
        //THIS IS ID OF PAYROLL PAGE WHICH IS NOT IN USE
        if (p.ID != 54) Page.create(mi, p);
      });
    parent.menuItems.add(mi.code, mi);
  }
}

export class Page extends UIElement {
  public pages: EventCollection<Page>;
  public tabs: EventCollection<Tab>;
  public buttons: EventCollection<Button>;
  public columns: EventCollection<Column>;

  constructor(collection, data) {
    super(collection, data);
    this.pages = new EventCollection<Page>();
    this.tabs = new EventCollection<Tab>();
    this.buttons = new EventCollection<Button>();
    this.columns = new EventCollection<Column>();
  }

  rebind(model) {
    this.pages.values.forEach((p) => p.rebind(model));
    this.tabs.values.forEach((t) => t.rebind(model));
    this.buttons.values.forEach((b) => b.rebind(model));
    this.columns.values.forEach((c) => c.rebind(model));
  }

  public static create(parent, data) {
    if (['set_regempl', 'set_cardDetails'].indexOf(data.Code) == -1) {
      let page = new Page(parent.pages, data);
      page.route = `${parent.route ? parent.route : ''}/${page.data.Route}`;
      if (data.Pages)
        data.Pages.forEach((p) => {
          Page.create(page, p);
        });
      if (data.Tabs)
        data.Tabs.forEach((p) => {
          Tab.create(page, p);
        });
      if (data.Buttons)
        data.Buttons.forEach((p) => {
          Button.create(page, p);
        });
      if (data.Columns)
        data.Columns.forEach((p) => {
          Column.create(page, p);
        });
      parent.pages.add(page.code, page);
      if (Session.landingPage == '' && page.data.IsLanding)
        Session.landingPage = page.route;
    }
  }
}

export class Tab extends UIElement {
  public tabs: EventCollection<Tab>;
  public buttons: EventCollection<Button>;
  public columns: EventCollection<Column>;
  constructor(collection, data) {
    super(collection, data);
    this.tabs = new EventCollection<Tab>();
    this.buttons = new EventCollection<Button>();
    this.columns = new EventCollection<Column>();
  }

  rebind(model) {
    super.rebind(model);
    this.tabs.values.forEach((t) => t.rebind(model));
    this.buttons.values.forEach((b) => b.rebind(model));
    this.columns.values.forEach((c) => c.rebind(model));
  }

  public static create(parent, data) {
    let tab = new Tab(parent.tabs, data);
    tab.route = `${tab.code.replace(`${parent.code}_`, '')}`;
    if (data.Tabs)
      data.Tabs.forEach((p) => {
        Tab.create(tab, p);
      });
    if (data.Buttons)
      data.Buttons.forEach((p) => {
        Button.create(tab, p);
      });
    if (data.Columns)
      data.Columns.forEach((p) => {
        Column.create(tab, p);
      });
    parent.tabs.add(tab.code, tab);
  }
}

export class Button extends UIElement {
  public onCompleted = new GSSubject();
  public onAction = new GSSubject();
  constructor(collection, data) {
    super(collection, data);
  }

  public complete() {
    this.onCompleted.next(this);
  }

  public action(id) {
    this.onAction.next(id);
  }

  public static create(parent, data) {
    let button = new Button(parent.buttons, data);
    parent.buttons.add(button.code, button);
  }
}

export class Column extends UIElement {
  constructor(collection, data) {
    super(collection, data);
  }

  public static create(parent, data) {
    let column = new Column(parent.columns, data);
    parent.columns.add(column.code, column);
  }
}

export class Cache {
  private storage = {};

  public set(group: string, key: string, value: any) {
    this.storage[group] = this.storage[group] ? this.storage[group] : {};
    this.storage[group][key] = value;
  }

  public get(group: string, key?: string) {
    if (this.storage[group]) {
      if (key != undefined) {
        return this.storage[group][key];
      } else {
        return this.storage[group];
      }
    } else {
      return null;
    }
  }

  public clear() {
    this.storage = {};
  }

  public remove(group: string, key?: string) {
    if (this.storage.hasOwnProperty(group)) {
      if (key) {
        if (this.storage[group].hasOwnProperty(key))
          delete this.storage[group][key];
      } else {
        delete this.storage[group];
      }
    }
  }

  public contains(group: string, key?: string): boolean {
    if (this.storage.hasOwnProperty(group))
      return key ? this.storage[group].hasOwnProperty(key) : true;
    else return false;
  }
}

export class Session {
  public static valid: boolean = false;
  public static menu: Menu;
  public static requestedRoute;
  public static token = '';
  public static userName: string;
  public static userRole: string;
  public static data;
  public static loading: boolean = false;
  public static cache: Cache = new Cache();
  public static broadcaster: SignalR;
  public static broadcastingClientId: string;
  public static counter = -1;
  public static landingPage: string = '';
  public static multipleSyncRequest = false;
  public static uiStates: Item = new Item(null, []);
  public static userCode: number;
  public static showSpinner = false;
  public static onRebindModel = new GSSubject();
  public static suppressSpinner = false;
  public static accountID: string;
  public static showVideo$ = new BehaviorSubject(false);
  public static showUnpaidNotificationDialog = false;
  public static subscribeToServiceStatusMessageEvent$ = new BehaviorSubject(false);
  public static workflowExecutionFinished$ = new BehaviorSubject(false);

  public static init(data) {
    Session.cache.clear();
    Session.data = data;
    Session.token = data.Token;
    Session.userName = data.Name;
    Session.userRole = data.Role;
    Session.userCode = data.UserCode;
    Session.accountID = data.AccountID;
  }

  public static clear() {
    Session.userCode = null;
    Session.data = null;
    Session.landingPage = '';
    Session.token = '';
    Session.userName = null;
    Session.userRole = null;
    Session.valid = false;
    Session.unsubscribeAll();
    Session.menu = null;
    Session.broadcastingClientId = null;
    Session.cache.clear();
    //BroadcastingClient.stop();
    Session.uiStates.items.clear();
    Session.showUnpaidNotificationDialog = false;
  }

  //NOTE *****NOT IN USE***** REMOVE IT AFTER SOME TIME
  public static createModel(parent, data, type) {
    data.forEach((child) => {
      if (parent instanceof Menu) {
        let menuItem = new MenuItem(parent.menuItems, child);
        //menuItem.code.split('_').forEach((s, i) => {
        //  if (i != 0)
        //    menuItem.route = s;
        //  else if (i > 1)
        //    menuItem.route = `_${s}`;
        //  else
        //    menuItem.route = menuItem.code;
        //});
        menuItem.route = menuItem.data.Route;
        if (child.Pages) this.createModel(menuItem, child.Pages, Page);

        parent.menuItems.add(menuItem.code, menuItem);
      } else if (parent instanceof MenuItem) {
        if (['set_regempl', 'set_cardDetails'].indexOf(child.Code) == -1) {
          let page = new Page(parent.pages, child);
          //page.route = `${parent.route ? parent.route : ''}/${page.code.replace(`${parent.code}_`, '').replace('_', '/')}`;
          page.route = `${parent.route ? parent.route : ''}/${page.data.Route}`;
          if (child.Pages) this.createModel(page, child.Pages, Page);
          if (child.Tabs) this.createModel(page, child.Tabs, Tab);
          if (child.Buttons) this.createModel(page, child.Buttons, Button);
          if (child.Columns) this.createModel(page, child.Columns, Column);

          parent.pages.add(page.code, page);
          if (Session.landingPage == '' && page.data.IsLanding)
            Session.landingPage = page.route;
        }
      } else if (parent instanceof Page) {
        if (type == Page) {
          let page = new Page(parent.pages, child);
          //page.route = `${parent.route ? parent.route : ''}/${page.code.replace(`${parent.code}_`, '').replace('_', '/')}`;
          page.route = `${parent.route ? parent.route : ''}/${page.data.Route}`;
          if (child.Pages) this.createModel(page, child.Pages, Page);
          if (child.Tabs) this.createModel(page, child.Tabs, Tab);
          if (child.Buttons) this.createModel(page, child.Buttons, Button);
          if (child.Columns) this.createModel(page, child.Columns, Column);

          parent.pages.add(page.code, page);
          if (Session.landingPage == '' && page.data.IsLanding)
            Session.landingPage = page.route;
        } else if (type == Tab) {
          let tab = new Tab(parent.tabs, child);
          tab.route = `${tab.code.replace(`${parent.code}_`, '')}`;
          //tab.route = `${parent.route ? parent.route : ''}/${tab.code.replace(`${parent.code}_`, '')}`;
          //tab.route = tab.data.Route;
          parent.tabs.add(tab.code, tab);
          if (child.Tabs) this.createModel(tab, child.Tabs, Tab);
          if (child.Buttons) this.createModel(tab, child.Buttons, Button);
          if (child.Columns) this.createModel(tab, child.Columns, Column);
        } else if (type == Button) {
          let button = new Button(parent.buttons, child);
          //button.route = `${parent.route ? parent.route : ''}/${button.code.replace(`${parent.code}_`, '')}`;
          parent.buttons.add(button.code, button);
        } else if (type == Column) {
          let column = new Column(parent.columns, child);
          //button.route = `${parent.route ? parent.route : ''}/${button.code.replace(`${parent.code}_`, '')}`;
          parent.columns.add(column.code, column);
        }
      } else if (parent instanceof Tab) {
        if (type == Button) {
          let button = new Button(parent.buttons, child);
          //button.route = `${parent.route ? parent.route : ''}/${button.code.replace(`${parent.code}_`, '')}`;
          parent.buttons.add(button.code, button);
        } else if (type == Column) {
          let column = new Column(parent.columns, child);
          //button.route = `${parent.route ? parent.route : ''}/${button.code.replace(`${parent.code}_`, '')}`;
          parent.columns.add(column.code, column);
        } else if (type == Tab) {
          let tab = new Tab(parent.tabs, child);
          tab.route = `${tab.code.replace(`${parent.code}_`, '')}`;
          //tab.route = `${parent.route ? parent.route : ''}/${tab.code.replace(`${parent.code}_`, '')}`;
          //tab.route = tab.data.Route;
          parent.tabs.add(tab.code, tab);
          if (child.Tabs) this.createModel(tab, child.Tabs, Tab);
          if (child.Buttons) this.createModel(tab, child.Buttons, Button);
          if (child.Columns) this.createModel(tab, child.Columns, Column);
        }
      }
    });
  }

  public static getByCode(model, code) {
    let res = model.find((m) => m.Code == code);
    if (res) return res;
    for (let el of model) {
      for (let prop of Object.getOwnPropertyNames(el)) {
        if (!(el[prop] instanceof Array)) continue;
        res = Session.getByCode(el[prop], code);
        if (res) break;
      }
      if (res) break;
    }
    return res;
  }

  public static rebindModel(model: any) {
    this.menu.rebind(model);
    // this.menu.data.forEach((menuItem) => {
    //   let modelMenuItem = model.find((mmi) => mmi.Code == menuItem.Code);

    //   menuItem.Pages.filter((p) => !p.IsMultiLocation).forEach((page) => {
    //     let modelPage = modelMenuItem.Pages.find((mp) => mp.Code == page.Code);

    //     page.Buttons.forEach((btn) => {
    //       //default
    //       btn.IsVisible = false;
    //       btn.IsDisabled = true;

    //       //override if overriden for customer/role
    //       let modelButton = modelPage.Buttons.find((mb) => mb.Code == btn.Code);
    //       if (modelButton) {
    //         btn.IsVisible = modelButton.IsVisible;
    //         btn.IsDisabled = modelButton.IsDisabled;
    //         btn.Title =
    //           modelButton.Title != null && modelButton.Title != undefined
    //             ? modelButton.Title
    //             : btn.Title;
    //       }
    //     });

    //     page.Tabs.forEach((tab) => {
    //       //default
    //       tab.IsVisible = false;
    //       tab.IsDisabled = true;

    //       //override if overriden for customer/role
    //       let modelTab = modelPage.Tabs.find((mt) => mt.Code == tab.Code);
    //       if (modelTab) {
    //         tab.IsVisible = modelTab.IsVisible;
    //         tab.IsDisabled = modelTab.IsDisabled;
    //         tab.Title =
    //           modelTab.Title != null && modelTab.Title != undefined
    //             ? modelTab.Title
    //             : tab.Title;

    //         tab.Columns.forEach((col) => {
    //           //default
    //           col.IsVisible = false;
    //           col.IsDisabled = true;

    //           //override if overriden for customer/role
    //           let modelColumn = modelTab.Columns.find(
    //             (mc) => mc.Code == col.Code
    //           );
    //           if (modelColumn) {
    //             col.IsVisible = modelColumn.IsVisible;
    //             col.IsDisabled = modelColumn.IsDisabled;
    //             col.Title =
    //               modelColumn.Title != null && modelColumn.Title != undefined
    //                 ? modelColumn.Title
    //                 : col.Title;
    //           }
    //         });
    //       }
    //     });
    //   });
    // });
    Session.onRebindModel.next();
  }

  public static unsubscribeComponent(subscriber) {
    if (subscriber) this.unsubscribeSubscriber(Session.menu, subscriber);
  }

  public static unsubscribeAll() {
    this.unsubscribeSubscriber(Session.menu);
  }

  private static unsubscribeSubscriber(parent, subscriber?) {
    if (!parent) return;

    (parent as UIElement).unsubscribeSubscriber(subscriber);

    if (parent instanceof Menu) {
      parent.menuItems.values.forEach((mi) => {
        mi.unsubscribeSubscriber(subscriber);
        this.unsubscribeSubscriber(mi, subscriber);
      });
    } else if (parent instanceof MenuItem) {
      parent.pages.values.forEach((pg) => {
        pg.unsubscribeSubscriber(subscriber);
        this.unsubscribeSubscriber(pg, subscriber);
      });
    } else if (parent instanceof Page) {
      parent.pages.values.forEach((pg) => {
        pg.unsubscribeSubscriber(subscriber);
        this.unsubscribeSubscriber(pg, subscriber);
      });
      parent.tabs.values.forEach((t) => {
        t.unsubscribeSubscriber(subscriber);
        this.unsubscribeSubscriber(t, subscriber);
      });
      parent.buttons.values.forEach((b) => {
        b.unsubscribeSubscriber(subscriber);
        this.unsubscribeSubscriber(b, subscriber);
      });
      parent.columns.values.forEach((c) => {
        c.unsubscribeSubscriber(subscriber);
        this.unsubscribeSubscriber(c, subscriber);
      });
    } else if (parent instanceof Tab) {
      parent.buttons.values.forEach((b) => {
        b.unsubscribeSubscriber(subscriber);
        this.unsubscribeSubscriber(b, subscriber);
      });
      parent.columns.values.forEach((c) => {
        c.unsubscribeSubscriber(subscriber);
        this.unsubscribeSubscriber(c, subscriber);
      });
    }
  }

  public static notifications: EventCollection<Notification> =
    new EventCollection<Notification>();
}

export class Notification {
  public id: string;

  constructor(
    public title: string,
    public message: string,
    public type: NotificationType,
    public lifems?: number
  ) {
    this.id = Tools.generateGUID();
    this.message = message;
    this.type = type;
    this.title = title;
  }
  public select(params?) {}

  public unselect(params?) {}
}

export enum NotificationType {
  Success,
  Error,
  Warning,
  Info,
}

export class PagedResultRequest {
  public pageIndex: number;
  public pageSize: number;

  public sortingInfo: Array<SortingInfo> = [];
  public filteringInfo: Array<SortingInfo> = [];

  constructor(pageIndex: number, pageSize: number) {
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
  }
}

export class FilteringInfo {
  public field: string;
  public value: string;
  public matchMode: string;
}

export class SortingInfo {
  public sortField: string;
  public sortOrder: number;
}

export class ValidationInfo {
  public fieldName?: string;
  public valid?: boolean;
  public column?: TableColumn;
  public property?: FormProperty;

  constructor(
    fieldName: string,
    column: TableColumn,
    property: FormProperty,
    valid: boolean
  ) {
    this.fieldName = fieldName;
    this.valid = valid;
    this.column = column;
    this.property = property;
  }
}
