<div
  [@navigationMaxMin]="navigationMinimize ? 'max' : 'min'"
  *ngIf="Session.valid"
  class="app-header"
>
  <div class="fa-pull-left center" style="padding-top: 8px; padding-left: 5px">
    <ul class="list-unstyled list-inline navigation-master-color pointer">
      <li>
        <span *ngIf="navigationMinimize" (click)="changeNavigation()">
          <i class="fas fa-bars fa-2x" [margin-left]="3"></i>
        </span>
      </li>
      <li>
        <span [padding-left]="5"
              [routerLink]="'home'"
              routerLinkActive="active">
          <img src="../assets/images/gs.logo.png" width="35px" height="35px" />
        </span>
      </li>
      <li>
        <span *ngIf="
            environment &&
            environment.portalCode == 'PDP' &&
            Session.menu.menuItems &&
            Session.menu.menuItems.selected &&
            Session.menu.menuItems.selected.pages.selected &&
            Session.menu.menuItems.selected.pages.selected.data &&
            Session.menu.menuItems.selected.pages.selected.data.customerName
          "
              [padding-left]="10"
              [padding-top]="5"
              class="text-m uppercase display-block">
          @
          {{ Session.menu.menuItems.selected.pages.selected.data.customerName }}
        </span>
      </li>
      <li>
        <span *ngIf="serviceStatusMessage"
              class="service-message service-message-container"
              (click)="showServiceStatusMessages($event)">
          <i *ngIf="serviceStatusMessage && serviceStatusMessage.length == 1"
             class="fas fa-exclamation-triangle"
            (click)="showServiceStatusMessages($event)">
          </i>
          <span *ngIf="serviceStatusMessage && serviceStatusMessage.length > 1"
                class="system-message-badge"
                (click)="showServiceStatusMessages($event)">
            {{serviceStatusMessage.length}}
          </span>
          {{serviceStatusMessage[0].Message}}
        </span>        
      </li>
      <li>
    </ul>
  </div>
  <div class="fa-pull-right display-flex height-max">
    <div
      *ngIf="showVideo"
      class="fa-pull-left pointer display-flex self-center toolbar-separator"
      style="color: var(--nav-main-background-color)"
      [padding]="5"
      [pTooltip]="'general.videoSwitch' | gstranslate"
      #helpButton
    >
      <img (click)="clickVideo($event)" src="../assets/icons/VIDEO_ICON.png" />
      <span (click)="clickVideo($event)" class="toolbar-text text-s">
        {{ "general.videoHelp" | gstranslate }}
      </span>
      <p-inputSwitch
        class="ui-inputwrapper-focus ui-inputwrapper-focus video-switch"
        (ngModelChange)="changeVideoVisibility($event)"
        [ngModel]="showVideoIcons"
      ></p-inputSwitch>
    </div>

    <div
      *ngIf="showHelp"
      class="fa-pull-left pointer display-flex toolbar-separator align-center"
    >
      <div
        style="color: var(--nav-main-background-color)"
        [padding]="5"
        #videoButton
        (click)="clickHelp($event)"
      >
        <i class="fas fa-question-circle toolbar-icon"></i>
      </div>
      <span class="toolbar-text text-s">
        {{ "general.helpDocument" | gstranslate }}
      </span>
    </div>
    <div
      class="fa-pull-left display-flex align-center pointer"
      (click)="user.toggle($event)"
      [padding-right]="5"
    >
      <span [padding]="5">
        <i class="far fa-user-circle toolbar-icon"></i>
      </span>
      <div>
        <span class="text-s bold display-block">{{ Session.userName }}</span>
        <span class="text-s display-block web-kit-right">
          {{ Session.userRole }}
        </span>
      </div>
    </div>
  </div>
</div>

<div
  [@minMax]="navigationMinimize ? 'min' : 'max'"
  *ngIf="Session.valid && Session.menu"
  class="navigation"
>
  <div class="menu-items">
    <div
      (click)="changeNavigation()"
      class="nav-heading justify-center center pointer"
      style="padding-top: 7px"
    >
      <i class="fas fa-bars fa-2x" [margin-left]="3"></i>
    </div>
    <ng-container *ngFor="let menuItem of Session.menu.menuItems.values">
      <div
        class="center"
        [ngClass]="{ 'navigation_menu-items_selected': menuItem.selected }"
      >
        <span
          *ngIf="menuItem.name != 'HomeComponent'"
          (click)="menuItem.select()"
          class="center pointer icon"
        >
          <i class="{{ menuItem.data.Icon }}"></i>
        </span>
      </div>
    </ng-container>
  </div>
  <div class="pages">
    <ng-container *ngIf="Session.menu.menuItems.selected">
      <div class="nav-heading text-xl" style="padding-left: 8px">
        <span>
          {{ Session.menu.menuItems.selected.title }}
        </span>
        <span
          [padding-right]="5"
          class="fa-pull-right pointer"
          (click)="changeNavigation()"
        >
          <i class="fas fa-angle-left"></i>
        </span>
      </div>

      <div class="text-s" style="padding-left: 12px">
        <ng-container
          *ngFor="let page of Session.menu.menuItems.selected.pages.values"
        >
          <span
            *ngIf="page.route != 'help/documents'"
            class="display-block pointer navigation-pages-page"
            [ngClass]="{ 'page-selected': page.selected }"
            [routerLink]="page.route"
            routerLinkActive="active"
            appVideoTraining
            [type]="page | pageTrainingVideo"
          >
            <span><i class="fas fa-angle-right"></i></span>
            <span [padding-left]="5">{{ page.title }}</span>
          </span>
          <span
            *ngIf="page.route == 'help/documents'"
            class="display-block pointer navigation-pages-page"
            [ngClass]="{ 'page-selected': page.selected }"
            (click)="clickHelp($event, true)"
          >
            <span><i class="fas fa-angle-right"></i></span>
            <span [padding-left]="5">{{ page.title }}</span>
          </span>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>

<div
  [@contentMaxMin]="navigationMinimize ? 'max' : 'min'"
  class="app-content"
  [ngClass]="{
    'login-background': !Session.valid && environment.portalCode == 'PDP',
    'login-background-admin': !Session.valid && environment.portalCode == 'PAP'
  }"
>
  <router-outlet></router-outlet>

  <div
    *ngIf="
      Session.valid &&
      Session.menu &&
      environment.portalCode == 'PDP' &&
      !showChatMenu &&
      !showChatGPT
    "
    style="
      position: fixed;
      bottom: 30px;
      right: 30px;
      width: 40px;
      height: 40px;
      background-color: white;
      border: 4px solid rgb(0, 164, 78);
      cursor: pointer;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      border-bottom-left-radius: 30px;
      display: flex;
      justify-content: center;
      z-index: 5000;
      align-items: center;
    "
    (click)="openChatGPT()"
  >
    <img src="../../../assets/images/chat_icon.png" width="16" height="15" />
    <!--<i class="fa fa-comments-o" aria-hidden="true" style="color: #00a44e; font-size: 20px; padding-top: 7px; font-weight: bold; "></i>-->
  </div>
  <div
    *ngIf="
      Session.valid &&
      Session.menu &&
      environment.portalCode == 'PDP' &&
      (showChatMenu || showChatGPT)
    "
    style="
      position: fixed;
      bottom: 30px;
      right: 30px;
      width: 40px;
      height: 40px;
      background-color: white;
      border: 4px solid rgb(0, 164, 78);
      cursor: pointer;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      border-bottom-left-radius: 30px;
      display: flex;
      justify-content: center;
      z-index: 5000;
    "
    (click)="closeChat()"
  >
    <i
      class="fa fa-chevron-down"
      aria-hidden="true"
      style="
        color: #1d3e7d;
        font-size: 16px;
        padding-top: 8px;
        font-weight: bold;
      "
    ></i>
  </div>
  <!--<div *ngIf="Session.valid && Session.menu && environment.portalCode == 'PDP' && showChatMenu">
    <div style="position: fixed;bottom: 80px;right: 30px;width: 270px;height: 200px;border: 2px solid #efefef;border-radius: 10px;background-color: white;box-shadow: 0px 0px 4px 4px #cfcfcf;">
      <div style="border-bottom:1px solid #efefef; width:245px;height:40px;margin-left:10px; margin-right:10px;">
        <div style="float: left;font-weight: bold;color: black;padding-left: 5px;padding-top: 5px;">Hello {{ Session.userName }}</div>
        <div style="float:right"><img src="../favicon.ico" width="30px" height="30px" /></div>
      </div>
      <div class="chat-menu-link" (click)="openChatGPT()">
        <i class="fa fa-comments-o chat-menu-link-icon" aria-hidden="true"></i>Ask us a question
      </div>
      <div class="chat-menu-link">
        <i class="fa fa-question-circle-o chat-menu-link-icon" aria-hidden="true"></i>Documents (Read/Print)
      </div>
      <div class="chat-menu-link">
        <i class="fa fa-play-circle-o chat-menu-link-icon" aria-hidden="true"></i>Video Help (Watch)
      </div>
      <div class="chat-menu-link">
        <i class="fa fa-life-ring chat-menu-link-icon" aria-hidden="true"></i>Send us Support Ticket
      </div>
    </div>
  </div>-->
  <div
    *ngIf="
      Session.valid &&
      Session.menu &&
      environment.portalCode == 'PDP' &&
      showChatGPT
    "
  >
    <div class="chat-gpt-box">
      <div
        style="
          width: 100%;
          height: 60px;
          background-color: #1d3e7d;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
          padding: 10px 0px 0px 0px;
        "
      >
        <div
          style="
            width: 100%;
            color: white;
            font-weight: bold;
            text-align: center;
            padding-top: 7px;
          "
        >
          PayDayPortal AI Help Desk
        </div>
      </div>
      <!--<div style="width: 100%;  background-color: #1d3e7d;">
        <div style="width:100%; color:white; text-align:center; font-size:14px;">Hello, {{Session.userName}}!</div>
      </div>
      <div style="width: 100%;  background-color: #1d3e7d;">
        <div style="width:100%; color:white; text-align:center; font-size:14px;padding-bottom:10px;">How can we help you?</div>
      </div>-->
      <div>
        <div *ngIf="showChatGPTSpinner" class="chat-spiner-container">
          <div
            class="chat-spiner"
            style="background-color: white; border-radius: 50%"
          >
            <img
              src="../../../assets/images/gs.logo.png"
              style="padding: 15px"
              width="100"
              height="100"
            />
          </div>
        </div>

        <div style="height: 480px; overflow-y: scroll" #chatGPtHistory>
          <div
            style="
              display: flex;
              justify-content: left;
              float: left;
              width: 400px;
              font-size: 14px;
            "
          >
            <div
              style="
                display: flex;
                justify-content: left;
                background-color: #e6f5ec;
                margin: 10px 10px 5px 10px;
                border: 1px solid #e6f5ec;
                border-radius: 30px;
                padding: 10px 15px;
                font-size: 14px;
                width: 400px;
                color: black;
                white-space: pre-wrap;
              "
            >
              Hello, {{ Session.userName }}! How can we help you?
            </div>
            <div
              style="
                font-size: 11px;
                vertical-align: middle;
                justify-content: center;
                display: flex;
                align-content: center;
                flex-wrap: wrap;
              "
            >
              PDP
            </div>
          </div>
          <div *ngFor="let message of chatGPTMessages">
            <div
              *ngIf="message.Role == 'assistant'"
              style="
                display: flex;
                justify-content: left;
                float: left;
                width: 400px;
                font-size: 14px;
              "
            >
              <div
                style="
                  display: flex;
                  justify-content: left;
                  background-color: #e6f5ec;
                  margin: 5px 10px 5px 10px;
                  border: 1px solid #e6f5ec;
                  border-radius: 30px;
                  padding: 10px 15px;
                  font-size: 14px;
                  width: 400px;
                  color: black;
                  white-space: pre-wrap;
                "
              >
                {{ message.Content }}
              </div>
              <div
                style="
                  font-size: 11px;
                  vertical-align: middle;
                  justify-content: center;
                  display: flex;
                  align-content: center;
                  flex-wrap: wrap;
                "
              >
                PDP
              </div>
            </div>

            <div
              *ngIf="message.Role == 'user'"
              style="
                display: flex;
                justify-content: right;
                float: right;
                width: 400px;
                font-size: 14px;
              "
            >
              <div
                style="
                  font-size: 11px;
                  vertical-align: middle;
                  justify-content: center;
                  display: flex;
                  align-content: center;
                  flex-wrap: wrap;
                "
              >
                You
              </div>
              <div
                style="
                  display: flex;
                  justify-content: left;
                  background-color: #f1f1f1;
                  margin: 5px 10px 5px 10px;
                  border: 1px solid #f1f1f1;
                  border-radius: 30px;
                  padding: 10px 15px;
                  font-size: 14px;
                  width: 400px;
                  color: black;
                  white-space: pre-wrap;
                "
              >
                {{ message.Content }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="chat-gpt-prompt"
        style="
          height: 60px;
          bottom: 80px;
          position: fixed;
          width: 450px;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          border-top: 1px solid #cccccc;
        "
      >
        <input
          class="chat-gpt-prompt-input"
          #chatGptPrompt
          type="text"
          id="chatGptPrompt"
          name="chatGptPrompt"
          (keyup.enter)="sendChatGPT()"
          style="
            color: #828282;
            position: fixed;
            bottom: 99px;
            width: 400px;
            margin-left: 2px;
            font-size: 14px;
            padding-left: 10px;
          "
          placeholder="Type your message..."
        />
        <!--<i class="fa fa-paper-plane-o" aria-hidden="true" style="position: fixed;bottom: 80px;right:30px;margin-right: 5px;margin-bottom: 5px;font-weight: bold;cursor:pointer;" (click)="sendChatGPT()"></i>-->
        <img
          src="../../../assets/images/send_chat.png"
          style="
            position: fixed;
            bottom: 78px;
            right: 30px;
            margin-right: 5px;
            margin-bottom: 15px;
            cursor: pointer;
          "
          width="35"
          height="35"
          (click)="sendChatGPT()"
        />
      </div>
    </div>
  </div>
</div>
<!-- <div class="app-footer">
  <span class="footer-text">
    Protected by Patent Nos.# 9,741,050 and #10,726,436
  </span>
</div> -->

<p-toast
  #notification
  [baseZIndex]="5001"
  [styleClass]="!navigationMinimize ? 'toast-max' : ''"
  (onClose)="toastClosed($event)"
></p-toast>
<p-overlayPanel #user>
  <div
    *ngIf="
      Session.accountID &&
      Session.accountID != '' &&
      environment &&
      environment.portalCode == 'PDP'
    "
    style="display: table-row; font-size: 13px"
  >
    <div
      style="
        display: table-cell;
        font-weight: bold;
        border-bottom: 1px solid #b0b0b0;
        padding-bottom: 6px;
      "
    >
      Account ID: {{ Session.accountID }}
    </div>
    <div
      class="pointer"
      style="
        display: table-cell;
        padding-left: 5px;
        border-bottom: 1px solid #b0b0b0;
        padding-bottom: 3px;
      "
      (click)="copyAccountID()"
    >
      <i class="fas fa-clone"></i>
    </div>
  </div>
  <ul
    class="list-unstyled"
    style="min-width: 180px; padding-top: 10px; margin-bottom: 0px"
  >
    <li *ngIf="showUserDetails" class="pointer" (click)="userDetails($event)">
      <ul class="list-inline">
        <li>
          <i class="fas fa-user"></i>
        </li>
        <li
          appVideoTraining
          [type]="TrainingVideos.ChangeAccountDetailsAndPassword"
        >
          <span class="text-m"> Details</span>
        </li>
      </ul>
    </li>
    <li class="pointer" (click)="logOut()">
      <ul class="list-inline">
        <li>
          <i class="fas fa-sign-out-alt"></i>
        </li>
        <li>
          <span class="text-m"> Log out</span>
        </li>
      </ul>
    </li>
  </ul>
</p-overlayPanel>

<p-overlayPanel
  (onHide)="this.isHelpShown = false; this.isFromNavigation = false"
  [ngClass]="{ documents: isFromNavigation }"
  [showCloseIcon]="true"
  class="help"
  #help
  [style]="{ width: '80%', height: '90%' }"
>
  <app-user-manual
    *ngIf="isHelpShown"
    [isFromNavigation]="isFromNavigation"
    [showAll]="true"
  ></app-user-manual>
</p-overlayPanel>

<!--<p-overlayPanel
  (onHide)="this.showTrainingVideo = false;"
  [ngClass]="{ documents: isFromNavigation }"
  [showCloseIcon]="true"
  class="help"
  #trainingVideos
  [style]="{ width: '80%', height: '90%' }"
  [dismissable]="false"
>
  <app-training-video *ngIf="showTrainingVideo"></app-training-video>
</p-overlayPanel>-->
<p-dialog
          [(visible)]="this.showTrainingVideo"
          [closable]="true"
          [ngClass]="{ documents: isFromNavigation }"
          [draggable]="false"
          [resizable]="false"
          [modal]="true"
          class="help"
          #trainingVideos
          [style]="{ width: '80%', height: '100%' }"
          >
  <ng-template pTemplate="headless">
    <app-training-video *ngIf="showTrainingVideo"></app-training-video>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="this.showServiceStatusMessageDialog"
          [closable]="true"
          [ngClass]="{ documents: isFromNavigation }"
          (onHide)="serviceStatusMessageDialogClosed($event)"
          [draggable]="false"
          [resizable]="false"
          [modal]="true"
          header="Active service status messages"
          #serviceStatusMessages
          [style]="{ width: '80%' }">
  
    <gs-table #serviceStatusMessage
              *ngIf="serviceStatusMessageItem"
              change
              [pageSize]="10"
              [totalResultsCnt]="serviceStatusMessageItem.items.keys.length"
              [showPaginator]="true"
              [wide]="true"
              [columns]="serviceStatusMessageColumns"
              [items]="serviceStatusMessageItem.items">
    </gs-table>
</p-dialog>

<p-confirmDialog
  [baseZIndex]="10000"
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>
<ngx-spinner zIndex="5000"></ngx-spinner>
<app-spinner *ngIf="Session.showSpinner" zIndex="5000"></app-spinner>
