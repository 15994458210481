export enum NotificationEvents {
  GeneralNotification = 0,
  PageDataUpdated = 1,
  InstallationPackageBuilt = 2,
  WizardStep_Dashboard_Updated = 3,
  WizardStep_Installation_Updated = 4,
  WizardStep_TendersMapping_Updated = 5,
  WizardStep_Distribution_Updated = 6,
  PayDay_POS_SyncFinished = 7,
  CalculationLambda_FinishedSuccess = 8,
  CalculationLambda_FinishedFailure = 9,
  WorkflowExecution_Finished = 10,
  ServiceStatusMessage_Updated = 11,
}
