import { Component, Inject, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { AppConfig } from '../../app.config';
import { Router, ActivatedRoute } from '@angular/router';
import { Session, NotificationType, Notification } from '../../common/entity/entities';
import { BrowserStorage } from '../../common/utils/tools';
import { SecurityHelper } from './helper/security.helper';
import { SecurityClient } from '../../client/security.client';
import { BaseLoginComponent } from './baseLogin.component';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { regExLib } from '../../common/regEx/regExLib';
import { DOCUMENT } from '@angular/common';
import { ServiceStatusMessageTypes } from '../../common/entity/enum/serviceStatusMessages';

@Component({
  selector: 'payDay-login',
  templateUrl: './payDayLogin.component.html',
  styleUrls: ['./payDayLogin.component.css'],
  providers: [
    //SecurityService, LocalizationService,
    AppConfig,
    UntypedFormBuilder,
    SecurityClient
    //, Session, VisualTreeBuilder
  ],
})
export class PayDayLoginComponent extends BaseLoginComponent {
  SITE_KEY = '6Lco27sZAAAAAOkOOd6hfJ1yjfgFlbjtZN532M6N';
  showDialog: boolean = false;
  showForgotPassDialog: boolean = false;
  changePaswordModel;
  forgotPaswordFinalModel;
  dialogForm;
  agreementURl;
  dialogHeader;
  dialogApply;
  dialogCancel;
  showSurvey;
  dialogWidth;
  forgotPassForm;
  forgotPassFormFinal;
  forgotPassFinalVisible;
  captchaValid = false;
  confirmationDialogVisible;
  confirmationHeader;
  confirmationMessage;
  captchaToken;
  contentURL;
  loading;
  showPassword = false;
  showIdentityProvider = false;
    identityProviderForm;
    identityProviderModel;

  @ViewChild('recaptcha') recaptchaElement;

  constructor(
    public route: ActivatedRoute,
    public config: AppConfig,
    public securityClient: SecurityClient,
    public router: Router,
    public formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    public sanitizer: DomSanitizer,
    public renderer: Renderer2,
    @Inject(DOCUMENT) public _document: Document,
    private activeRoute: ActivatedRoute
  ) {
    super(
      route,
      config,
      securityClient,
      router,
      formBuilder,
      translate,
      renderer,
      _document
    );
    BrowserStorage.useFor(this);
    this.form = formBuilder.group({
      user: this.user,
      password: this.password,
    });
    this.contentURL = this.sanitizer.bypassSecurityTrustResourceUrl(
      environment.loginIframeURL
    );
    this.activeRoute.params.subscribe((params) => {
      console.log('params', params);
    });
    this.activeRoute.queryParams.subscribe((params) => {
      if (params['error']) {
        console.log('Error: ', params['error']);
      }
      else if (params['reason']) { //possible when auth-error
        let notification: Notification = new Notification(
          "Error",
          params['reason'],
          NotificationType.Error,
          3000
        );
        Session.notifications.add(notification.id, notification);
      }
      else if (params['code']) this.oktaLoginHandle(params['code']);
    });
  }
  resolved(event) {
    this.captchaToken = event;
    if (!event) {
      this.captchaValid = false;
    } else {
      //this.captchaValid = false;
      //this.securityClient.captchaVerify(this.translate.instant('forgotPass.verifyingCaptcha'), event).then(resp => {
      this.captchaValid = true;
      //});
    }
  }

  login(e) {
    if (this.form.invalid) return;
    this.loading = true;
    e.preventDefault();
    this.securityClient
      .login(
        this.user.value,
        this.password.value,
        this.translate.instant('login.SignInInProgress')
      )
      .then(
        (data) => {
          this.handleLogin(data);
        },
        (reject) => {
          this.password.setValue(null);
          this.loading = false;
        }
      );
  }

  oktaLoginHandle(oneTimeCode) {
    this.loading = true;
    this.securityClient.exchangeToken(oneTimeCode).then(
      (data) => {
        this.handleLogin(data);
      },
      (reject) => {
        this.password.setValue(null);
        this.loading = false;
      }
    );
  }

  handleLogin(data) {
    this.agreementURl = undefined;
    this.dialogForm = undefined;
    let response = data.result;
    this.sessionObject = response;
    Session.init(response);
    this.securityClient.reinitTokenHeader();
    if (response.ChangePassUponLogin) this.setChangePassword();
    else if (Session.data.ShowUserAgreement) this.setAgreement();
    else if (response.ShowSurvey) this.setSurvey();
    else {
      SecurityHelper.getMenu(
        this.securityClient,
        this.router,
        this.translate.instant('login.AuthorizingUser')
      ).then((rejects) => {
        this.loading = false;
        this.progress = false;
      });
      if (environment.portalCode == 'PDP')
        SecurityHelper.getActiveServiceStatusMessage(
          this.securityClient,
          ServiceStatusMessageTypes.ServiceStatusMessage
        ).then(
          (response) => {
            if (response?.result)
              localStorage.setItem(`serviceStatusMessage_${Session.userCode}`, response.result)
          },
          (reject) => {
          });
    }
  }

  public apply() {
    if (this.agreementURl)
      this.securityClient.acknowledge().then((data) => {
        this.showDialog = false;
        this.changePaswordModel = undefined;
        if (Session.data.ShowSurvey) this.setSurvey();
        else
          SecurityHelper.getMenu(
            this.securityClient,
            this.router,
            this.translate.instant('login.AuthorizingUser')
          ).then((rejects) => {
            this.progress = false;
          });
          if(environment.portalCode == 'PDP')
            SecurityHelper.getActiveServiceStatusMessage(
              this.securityClient,
              ServiceStatusMessageTypes.ServiceStatusMessage
            ).then(
              (response) => {
                if (response?.result)
                  localStorage.setItem(`serviceStatusMessage_${Session.userCode}`, response.result)
              },
              (reject) => {
              });
      });
    else if (this.changePaswordModel) {
      this.dialogForm.controls['confirmPassword'].updateValueAndValidity();
      if (this.dialogForm.valid) {
        this.securityClient
          .changePassword(
            this.changePaswordModel.oldPassword,
            this.changePaswordModel.newPassword,
            this.translate.instant('login.changePassword')
          )
          .then((data) => {
            this.showDialog = false;
            this.dialogForm = undefined;
            this.changePaswordModel = undefined;
            if (Session.data.ShowUserAgreement) {
              this.setAgreement();
            } else if (Session.data.ShowSurvey) this.setSurvey();
            else {
              SecurityHelper.getMenu(
                this.securityClient,
                this.router,
                this.translate.instant('login.AuthorizingUser')
              ).then((rejects) => {
                this.progress = false;
              });
              if (environment.portalCode == 'PDP')
                SecurityHelper.getActiveServiceStatusMessage(
                  this.securityClient,
                  ServiceStatusMessageTypes.ServiceStatusMessage
                ).then(
                  (response) => {
                    if (response?.result)
                      localStorage.setItem(`serviceStatusMessage_${Session.userCode}`, response.result)
                  },
                  (reject) => {
                  });
            }
          });
      }
    } else if (this.showSurvey) console.log('not implemented');
  }

  public cancel() {
    this.showDialog = false;
    this.changePaswordModel = undefined;
  }

  public print() {
    window.open(this.agreementURl);
  }

  private setChangePassword() {
    this.dialogHeader = this.translate.instant('login.changePasswordHeader');
    this.dialogApply = this.translate.instant('general.apply');
    this.dialogCancel = this.translate.instant('general.cancel');
    this.dialogWidth = '350px';
    this.changePaswordModel = {
      oldPassword: undefined,
      showOld: false,
      newPassword: undefined,
      showNew: false,
      confirmPassword: undefined,
      showConfirm: false,
    };

    this.dialogForm = this.formBuilder.group({
      oldPassword: new UntypedFormControl('', Validators.required),
      newPassword: new UntypedFormControl(
        '',
        Validators.pattern(regExLib.regex_ComplexPassword12)
      ),
      confirmPassword: new UntypedFormControl('', this.checkPasswords),
    });
    this.showDialog = true;
  }

  checkPasswords(group: UntypedFormGroup) {
    // here we have the 'passwords' group
    let pass;
    let confirmPass;
    if (group.root.get('newPassword'))
      pass = group.root.get('newPassword').value;
    if (group.root.get('confirmPassword'))
      confirmPass = group.root.get('confirmPassword').value;

    return pass === confirmPass ? null : { notSame: true };
  }

  private setAgreement() {
    this.showDialog = true;
    this.dialogHeader = this.translate.instant('login.manageAgreement');
    this.dialogApply = this.translate.instant('general.agree');
    this.dialogCancel = this.translate.instant('general.print');
    this.dialogWidth = '950px';
    this.agreementURl = Session.data.AgreementURL;
  }

  private setSurvey() {
    //TEMPORARILY COMMENTED:
    //confirm("THERE ARE UNCOMPLETED SURVEYS FOR YOUR CUSTOMERS");

    SecurityHelper.getMenu(
      this.securityClient,
      this.router,
      this.translate.instant('login.AuthorizingUser')
    ).then((rejects) => {
      this.progress = false;
    });
  }

  forgotPasswordClick(e) {
    e.preventDefault();
    this.forgotPassFinalVisible = false;
    this.captchaValid = false;
    this.forgotPassForm = this.formBuilder.group({
      emailOrPhone: new UntypedFormControl('', Validators.required),
      username: new UntypedFormControl('', Validators.required),
    });

    this.forgotPaswordFinalModel = {
      oldPassword: undefined,
      showOld: false,
      newPassword: undefined,
      showNew: false,
      confirmPassword: undefined,
      showConfirm: false,
    };

    this.forgotPassFormFinal = this.formBuilder.group({
      verificationCode: new UntypedFormControl('', Validators.required),
      newPassword: new UntypedFormControl('', this.checkForgotNewPasswords),
      confirmPassword: new UntypedFormControl('', this.checkForgotPasswords),
    });
    this.showForgotPassDialog = true;
  }

  checkForgotPasswords(group: UntypedFormGroup) {
    // here we have the 'passwords' group
    let pass;
    let confirmPass;
    if (group.root.get('newPassword'))
      pass = group.root.get('newPassword').value;
    if (group.root.get('confirmPassword'))
      confirmPass = group.root.get('confirmPassword').value;

    return pass === confirmPass ? null : { notSame: true };
  }

  checkForgotNewPasswords(group: UntypedFormGroup) {
    // here we have the 'passwords' group
    let pass;
    let confirmPass;
    if (group.root.get('newPassword'))
      pass = group.root.get('newPassword').value;
    if (group.root.get('confirmPassword'))
      confirmPass = group.root.get('confirmPassword').value;

    if (!(pass === confirmPass) && group.root.get('confirmPassword'))
      group.root.get('confirmPassword').setErrors({ notSame: true });

    if (group.root.get('newPassword')) {
      let regEx: RegExp = regExLib.regex_ComplexPassword12;
      if (!regEx.test(group.root.get('newPassword').value))
        return { incorrect: true };
    }
    return null;
  }

  forgotPassRequest() {
    this.securityClient
      .sendForgotPasswordInquiry(
        this.translate.instant('forgotPass.sendingInquiry'),
        this.captchaToken,
        this.forgotPassForm.root.get('emailOrPhone').value,
        this.forgotPassForm.root.get('username').value
      )
      .then((result) => {
        this.forgotPassFinalVisible = true;
        this.confirmationHeader = this.translate.instant(
          'forgotPass.confirmationResetRequest'
        );
        this.confirmationMessage = result.result;
        this.confirmationDialogVisible = true;
      });
  }

  forgotPassRequestFinal() {
    this.securityClient
      .resetPassword(
        this.translate.instant('forgotPass.resettingPassword'),
        this.forgotPassFormFinal.root.get('verificationCode').value,
        this.forgotPassFormFinal.root.get('newPassword').value
      )
      .then((result) => {
        this.forgotPassFinalVisible = false;
        this.showForgotPassDialog = false;
        //confirmation popup
        this.confirmationHeader = this.translate.instant(
          'forgotPass.confirmationResetFinal'
        );
        this.confirmationMessage = this.translate.instant(
          'forgotPass.confirmationMessageResetFinal'
        );
        this.confirmationDialogVisible = true;
      });
  }

  closeConfirmationDialog() {
    this.confirmationDialogVisible = false;
  }

  show() {
    this.showPassword = !this.showPassword;
  }

  oktaLogin(e) {
    e.preventDefault();
    //window.location.replace(environment.oktaLoginURL);
    this.showIdentityProvider = true;
    this.identityProviderForm = this.formBuilder.group({
      identityProviderCode: new UntypedFormControl('', Validators.required)
    });
      this.identityProviderModel = { identityProviderCode: "" };
  }

    closeIdentityProviderDialog() {
      this.identityProviderModel = { identityProviderCode: "" };
      this.showIdentityProvider = false;
  }

  confirmIdentityProviderDialog() {
    var code = this.identityProviderForm.get('identityProviderCode').value;
    this.securityClient.getIdentityProvider({ code: code }).then((response) => {
      this.identityProviderModel = { identityProviderCode: "" };
      if (response != null && response.result != null && response.result.LoginUrl != null && response.result.LoginUrl != "") {
        this.showIdentityProvider = false;
        window.location.replace(response?.result.LoginUrl);
      }       
    });
  }
}
