import { Directive, Inject, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { AppConfig } from '../../app.config';
import { Router, ActivatedRoute } from '@angular/router';
import { Session} from '../../common/entity/entities';
import { LocalStorage } from '../../common/decorators/decorators';
import { BrowserStorage } from '../../common/utils/tools';
import { SecurityHelper } from './helper/security.helper';
import { SecurityClient } from '../../client/security.client';
import { DOCUMENT } from '@angular/common';
import { ServiceStatusMessageTypes } from '../../common/entity/enum/serviceStatusMessages';

@Directive()
export class BaseLoginComponent {
  user = new UntypedFormControl('', [Validators.required, startWithSpace()]);
  password = new UntypedFormControl('', [
    Validators.required,
    startWithSpace(),
  ]);
  form: UntypedFormGroup;
  progress: boolean = false;

  @LocalStorage('grat_auth') public sessionObject;
  @LocalStorage('grat_menu') public menuObject;

  constructor(
    public route: ActivatedRoute,
    public config: AppConfig,
    public securityClient: SecurityClient,
    public router: Router,
    formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    public baseRenderer: Renderer2,
    @Inject(DOCUMENT) public _baseDocument: Document
  ) {
    BrowserStorage.useFor(this);
    this.form = formBuilder.group({
      user: this.user,
      password: this.password,
    });
  }

  ngOnInit() {
    this.translate.setDefaultLang('en'); //Session.regionalsettings.Values["Code"].Value);
  }

  login(e?) {
    this.securityClient
      .login(
        this.user.value,
        this.password.value,
        this.translate.instant('login.SignInInProgress')
      )
      .then(
        (response) => {
          this.sessionObject = response?.result;
          Session.init(response?.result);
          this.securityClient.reinitTokenHeader();
          SecurityHelper.getMenu(
            this.securityClient,
            this.router,
            this.translate.instant('login.AuthorizingUser')
          ).then((rejects) => {
            this.progress = false;
          });
          SecurityHelper.getActiveServiceStatusMessage(
            this.securityClient,
            ServiceStatusMessageTypes.ServiceStatusMessage
          ).then(
            (response) => {
            if (response?.result)
              localStorage.setItem(`serviceStatusMessage_${Session.userCode}`, response.result)
            },
            (reject) => {
          });
        },
        (reject) => {}
      );
  }
}

export function startWithSpace(): (
  UntypedFormControl
) => ValidationErrors | null {
  return (control: UntypedFormControl): ValidationErrors | null => {
    return control?.value?.startsWith(' ') ? { startWithSpace: true } : null;
  };
}
