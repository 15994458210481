<div [ngClass]="{'row': !column, 'form-property':!column}">
  <div
    style="padding-right: 15px"
    [ngClass]="{'no-padding-right': tooltip||readonly, 'col-lg-11': tooltip && !column,  'col-lg-12': !tooltip && !column}"
  >
    <span [ngClass]="{'p-float-label':!column}">
      <input
        decimal
        id="{{name}}"
        type="text"
        style="width: 100%; -webkit-appearance: none; margin: 0"
        [ngStyle]="{'text-align': property ? (property.align || property.align ==0) ? (property.align==0?'center':(property.align==1 ? 'right':'left')) :'right'
                        : column.align? column.align:'right'}"
        [property]="property"
        [item]="item"
        [readonly]="readonly"
        [column]="column"
        [stringItem]="stringItem"
        (ngModelChange)="change($event)"
        class="table-control p-inputtext table-control-fill p-inputwrapper-focus p-inputwrapper-focus"
        [(ngModel)]="stringItem.stringVal"
        [ngClass]="{'disabled':readonly && !column,'currencydisabled':(readonly && column) || forceReadonly, 'property-invalid': (!readonly && notValid)}"
      />
      <label class="gs-float-label" [ngClass]="{'error-color':(!readonly && notValid)}"
        >{{label}}</label
      >
      <span
        *ngIf="property && (!readonly && notValid)"
        class="form-error"
        pTooltip="{{property.validationMessage}}"
      >
        <i class="fas fa-info-circle fa-2x form-valid-icon error-color"></i>
      </span>
    </span>
  </div>
  <div *ngIf="property && tooltip" class="col-lg-1 property-tooltip">
    <span pTooltip="{{property.tooltip}}">
      <i class="fas fa-info-circle master-color"></i>
    </span>
  </div>
</div>
