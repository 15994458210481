import {
  Component,
  OnInit,
  Compiler,
  ViewChild,
  Injector,
  HostListener,
  Renderer2,
  Inject,
  OnDestroy,
} from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import {
  Session,
  UIElement,
  Menu,
  Notification,
  NotificationType,
  Page,
  MenuItem,
  Item,
} from '../app/common/entity/entities';
import { MessageService } from 'primeng/api';
import { LocalStorage } from './common/decorators/decorators';
import { BrowserStorage, WebStorageUtility, Tools } from './common/utils/tools';
import { TranslateService } from '@ngx-translate/core';
import { SecurityHelper } from './menuItems/security/helper/security.helper';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { environment } from 'src/environments/environment';
import { SecurityClient } from './client/security.client';
import { SignalR } from 'ng2-signalr';
import { NotificationEvents } from './common/entity/enum/notificationEvents';
import { Guid, UpdateService } from './common/utils/utils';
import { SwUpdate } from '@angular/service-worker';
import { Title } from '@angular/platform-browser';
import { UserManualDirective } from './common/directives/userManual';
import { DatePipe, DOCUMENT } from '@angular/common';

import { BroadcastService } from './common/client/broadcast.service';
import { first, takeUntil } from 'rxjs/operators';
import { CustomerStore } from './menuItems/customerWizard/@state/customer.store';
import { TrainingVideos } from './common/entity/enum/trainingVideos';
import { TableColumn } from './common/entity/table/tableColumn';
import { TableTypes } from './common/entity/enums';
import { TableBuilder } from './common/builder/gs.table.builder';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  animations: [
    trigger('minMax', [
      state('min', style({ width: '0px' })),
      state('max', style({ width: '*' })),
      transition('min <=> max', animate('300ms ease-in')),
    ]),
    trigger('contentMaxMin', [
      state('min', style({ marginLeft: '255px' })),
      state('max', style({ marginLeft: '0px' })),
      transition('min <=> max', animate('300ms ease-in')),
    ]),
    trigger('navigationMaxMin', [
      state('min', style({ marginLeft: '255px', paddingRight: '255px' })),
      state('max', style({ marginLeft: '0px', paddingRight: '0' })),
      transition('min <=> max', animate('300ms ease-in')),
    ]),
  ],
  providers: [MessageService, SecurityClient, UpdateService],
})
export class AppComponent implements OnInit, OnDestroy {
  Session = Session;
  @ViewChild('user') user;
  @ViewChild('helpButton') helpButton;
  @ViewChild('help') help;
  @ViewChild('trainingVideos') trainingVideos;
  @ViewChild('helpIframe') iframeHelp;
  helpSrc: string;
  @ViewChild('notification') notification;
  requiredRoute: string;
  currentRoute: string;
  navigationMinimize = true;
  environmentName = '';
  environment = environment;
  isHelpShown = false;
  isFromNavigation = false;
  TrainingVideos = TrainingVideos;
  showServiceStatusMessageDialog = false;
  serviceStatusMessageItem: Item;
  signalRConnected$ = new BehaviorSubject(false);
  subscribedToServiceStatusMessageEvent: boolean = false;

  get serviceStatusMessage() {
    if (environment.portalCode == 'PDP'
      && Session.valid
      && Session.userCode
      && localStorage?.getItem(`serviceStatusMessage_${Session.userCode}`)) {
      var messages = JSON.parse(localStorage.getItem(`serviceStatusMessage_${Session.userCode}`));
      return messages;
    }
    else
      return null;
  }
  get showVideoIcons() {
    return Session.showVideo$.value;
  }

  @LocalStorage('grat_auth') private sessionObject;
  @LocalStorage('tabs') private tabs;

  get showUserDetails() {
    if (
      Session.menu &&
      Session.menu.menuItems.get('controlpanel') &&
      Session.menu.menuItems.get('controlpanel').pages.get('change_my_details')
    )
      return Session.menu.menuItems
        .get('controlpanel')
        .pages.get('change_my_details').data;
  }

  get isSamePage() {
    return Session?.token == '' && this.sessionObject;
  }

  get selectedPage() {
    return Session.menu?.menuItems?.selected?.pages?.selected;
  }

  get showHelp() {
    return Session.menu?.menuItems.values.some(
      (x) => x.data?.UserManuals?.length
    );
  }

  showVideo = true;
  showTrainingVideo = false;

  showChatMenu = false;
  showChatGPT = false;
  @ViewChild('chatGptPrompt') chatGptPrompt;
  @ViewChild('chatGPtHistory') chatGPTHistory;
  chatGPTMessages = null;
  chatGPTResponse = null;
  showChatGPTSpinner = false;
  serviceStatusMessageColumns: TableColumn[] = [];

  constructor(
    private _compiler: Compiler,
    private _injector: Injector,
    private _router: Router,
    private securityClient: SecurityClient,
    private translate: TranslateService,
    private messageService: MessageService,
    private _signalR: SignalR,
    private swUpdate: SwUpdate,
    private title: Title,
    private sw: UpdateService,
    private _usermanual: UserManualDirective,
    private renderer: Renderer2,
    private broadcastService: BroadcastService,
    private datePipe: DatePipe,
    @Inject(DOCUMENT) private _document: Document,
    private customerStore: CustomerStore
  ) {
    this.environmentName = environment.environmentName;

    BrowserStorage.useFor(this);
    this._router.events.subscribe(this.routerEvents.bind(this));
    Session.notifications.onAdded.subscribe((notification) => {
      this.pushNotification(notification as Notification);
    }, this);
    Session.notifications.onRemoved.subscribe((notification) => {
      //this.popNotification(notification as Notification);
    }, this);
  }

  ngOnInit() {
    //THESE TREE LINES ARE ADDED BECAUSE OF LOGIN IN AFTER REFRESH
    WebStorageUtility.remove(localStorage, 'grat_auth');
    WebStorageUtility.remove(localStorage, `serviceStatusMessage_${Session.userCode}`);
    Session.clear();

    this.translate.setDefaultLang('en');
    this.setTitle();
    if (this.swUpdate.isEnabled) {
    }
    this.sw.checkForUpdates();
    const showVideo = localStorage.getItem('showVideo')
      ? JSON.parse(localStorage.getItem('showVideo'))
      : false;
    if (showVideo) Session.showVideo$.next(true);

    Session.subscribeToServiceStatusMessageEvent$.subscribe((value) => {
        this.subscribeToSignalRServiceStatusMessage(value);
    });
  }

  clickHelp(event, isFromNavigation?) {
    this.help.toggle(event);
    this.isHelpShown = true;
    this.isFromNavigation = isFromNavigation;
  }

  clickVideo(event, isFromNavigation?) {
    //this.trainingVideos.toggle(event);
    this.showTrainingVideo = true;
  }

  showServiceStatusMessages(event, isFromNavigation?) {

    if (this.serviceStatusMessageColumns.length == 0) {
      this.serviceStatusMessageColumns = TableBuilder.build(
        TableTypes.ActiveServiceStatusMessages
      );
    }
    let data = JSON.parse(localStorage.getItem(`serviceStatusMessage_${Session.userCode}`));
    this.serviceStatusMessageItem = new Item(null, data);

    data.forEach((x, i) => {
      this.serviceStatusMessageItem.setItem(x, i);
    });
    
    this.showServiceStatusMessageDialog = true;
  }

  serviceStatusMessageDialogClosed(event) {
    this.serviceStatusMessageItem = null;
  }

  //#region UI EVENTS

  routerEvents(event) {
    if (event instanceof NavigationStart) {
      this.handleNavigationStartEvent(event);
    } else if (event instanceof NavigationEnd) {
      if (
        event.urlAfterRedirects &&
        event.url != event.urlAfterRedirects &&
        !this.Session.menu.items.selected
      ) {
        this.selectModelItem(Session.menu, event.urlAfterRedirects);
      }
    }
  }

  public logOut() {
    let child;
    for (let index = 0; index < this._document.scripts.length; index++) {
      if (
        this._document.scripts[index].src ===
        'https://assets.app.futr.ai/webchat/futrwebchat.js'
      )
        child = this._document.scripts[index];
    }
    if (child != null) this.renderer.removeChild(this._document, child);
    this.subscribedToServiceStatusMessageEvent = false;
    this.navigationMinimize = true;
    WebStorageUtility.remove(localStorage, 'grat_auth');
    WebStorageUtility.remove(localStorage, `serviceStatusMessage_${Session.userCode}`);
    Session.clear();
    this._router.navigate(['/login']);
    this.user.hide();
    this.broadcastService.disconnect();
    this.customerStore.clear();
  }

  userDetails(e) {
    this._router.navigate(['/controlpanel/changeMyDetails']);
  }

  public changeNavigation() {
    this.navigationMinimize = !this.navigationMinimize;
  }

  @HostListener('window:unload', ['$event'])
  beforeunloadHandler(event) {
    let tabId = this.tabs.find((t) => t == sessionStorage.getItem('tabId'));
    let tabs = this.tabs;
    tabs.splice(this.tabs.indexOf(tabId), 1);
    if (!tabs.length) {
      WebStorageUtility.remove(localStorage, 'grat_auth');
      Session.clear();
    } else this.tabs = tabs;
  }

  //#endregion

  //#region SIGNAL R

  private startSignalR() {
    //open signalr connection
    if (!Session.broadcastingClientId && Session.valid) {
      Session.broadcaster = this._signalR;
      Session.broadcastingClientId = Tools.generateGUID();
      //  BroadcastingClient .start().then(
      //     () => {
      //       BroadcastingClient.on(
      //         null,
      //         NotificationEvents.GeneralNotification,
      //         this.notificationReceived
      //       );
      //     },
      //     () => {
      //       Session.broadcastingClientId = null;
      //     }
      //   );
      this.broadcastService.init().then((data) => {
        const requestId = Guid.newGuid();
        this.broadcastService
          .subscribe({
            customerCode: null,
            event: NotificationEvents.GeneralNotification,
            requestId,
          })
          .pipe(first())
          .subscribe((data) => {
            console.log(`On Subscribe message: ${data}`);
          });
        this.broadcastService
          .tokenExpired()
          .pipe(first())
          .subscribe((data) => {
            this.logOut();
          });
        this.signalRConnected$.next(true);
      });
    }
  }

  subscribeToSignalRServiceStatusMessage = (subscribeToServiceStatusMessageUpdated: boolean) =>  {
    if (subscribeToServiceStatusMessageUpdated
      && !this.subscribedToServiceStatusMessageEvent
      && Session.cache.contains('Customers', 'All')) {
      if (this.broadcastService.isConnected()) {
        console.log('Connected to signalR');
        let allActiveCustomers = Session.cache.get('Customers', 'All');
        let distinctGroupCodes = [...new Set(allActiveCustomers.result.Customers.map(obj => obj.GroupCode))];

        distinctGroupCodes.forEach(gCode => {
          if (gCode) {
            this.subscribedToServiceStatusMessageEvent = true;
            this.broadcastService
              .subscribe({
                customerCode: null,
                event: NotificationEvents.ServiceStatusMessage_Updated,
                requestId: gCode.toString(),
                expiresInSeconds: 86400 //one day
              })
              .pipe(takeUntil(this.broadcastService
                .tokenExpired()))
              .subscribe((data) => {
                localStorage.setItem(`serviceStatusMessage_${Session.userCode}`, data.Message);
              });
          }
        });
      }
      else {
        console.log('Not connected to signalR');
        this.signalRConnected$.subscribe(this.subscribeToSignalRServiceStatusMessage);
      } 
    }
  }

  notificationReceived(message) {
    console.log('General notification received through SignalR.', message);
    //if (message && message.Message)
    //  ToasterHelper.showInfo(message.Message);
  }

  //#endregion

  //#region NOTIFICATION TOASTER
  addSingleToast(
    id: string,
    severity: string,
    summary: string,
    detail: string,
    lifems?
  ) {
    this.messageService.add({
      id: id,
      sticky: lifems ? false : true,
      severity: severity,
      summary: summary,
      detail: detail,
      life: lifems,
    });
  }

  clearAllToasts() {
    this.notification.modal = false;
    this.messageService.clear();
  }

  private pushNotification(notification: Notification) {
    let messageSeverity = 'info';
    switch (notification.type) {
      case NotificationType.Success:
        messageSeverity = 'success';
        this.notification.position = 'bottom-left';
        break;
      case NotificationType.Info:
        messageSeverity = 'info';
        this.notification.position = 'bottom-left';
        break;
      case NotificationType.Warning:
        messageSeverity = 'warn';
        this.notification.position = 'bottom-left';
        break;
      case NotificationType.Error:
        messageSeverity = 'error';
        this.notification.position = 'top-center';
        break;
    }

    if (notification.message || notification.title)
      this.addSingleToast(
        notification.id,
        messageSeverity,
        notification.title,
        notification.message,
        notification.lifems
      );

    if (notification.lifems) {
      setTimeout(() => {
        Session.notifications.remove(notification.id);
      }, notification.lifems);
    }
  }

  private popNotification(notification: Notification) {
    this.clearAllToasts();
  }

  toastClosed(event) {
    Session.notifications.remove(event.message.id);
  }
  //#endregion

  //#region PRIVATE METHODS

  private handleNavigationStartEvent(event) {
    this.manageTabs();
    if (this.isSamePage) {
      this.refreshPage(event);
    } else {
      this.navigateToPage(event);
    }
  }

  private refreshPage(event) {
    Session.init(this.sessionObject);
    Session.requestedRoute = event.url;

    SecurityHelper.getMenu(this.securityClient, this._router).then(
      (response) => {
        this.startSignalR();
        this.selectModelItem(Session.menu, event.url);
      },
      (reject) => {
        this._router.navigate(['/login']);
        this.navigationMinimize = true;
        Session.valid = false;
        Session.token = null;
      }
    );
  }

  private navigateToPage(event) {
    if (this.isLoginOrSignUp(event)) {
      this.handleLoginOrSignUpPage();
    } else {
      this.startSignalR();     
    }

    if (event.url == '/adm_help/help') {
      if (this.showHelp) {
        this.help.toggle(event, this.help.el.nativeElement);
        this.isHelpShown = true;
      }
    } else {
      if (this.sessionExistsAndRouteChanged(event)) {
        this.selectModelItem(Session.menu, event.url);
      }

      this.currentRoute = event.url;
      if (this.hasNoTokenAndNotLoginOrSignUp(event)) {
        if (
          event.url.indexOf('auth-error') == -1 &&
          event.url.indexOf('auth-callback') == -1
        )
          this.rememberRequestedRouteAndNavigateToLogin(event);
        //else this._router.navigateByUrl(this.currentRoute);
      }

      if (
        Session.token == '' &&
        event.url == '/signup' &&
        environment.portalCode != 'PDP'
      )
        this._router.navigate(['/login']);
    }
  }

  private sessionExistsAndRouteChanged(event) {
    return this.currentRoute != event.url && Session.menu != null;
  }

  private hasNoTokenAndNotLoginOrSignUp(event) {
    return (
      Session.token == '' && event.url != '/login' && event.url != '/signup'
    );
  }

  private rememberRequestedRouteAndNavigateToLogin(event) {
    Session.requestedRoute = event.url;
    if (Session.requestedRoute == '/') Session.requestedRoute = '/home';

    this._router.navigate(['/login']);
  }

  private isLoginOrSignUp(event) {
    return (
      event.url == '/login' ||
      (event.url == '/signup' && environment.portalCode == 'PDP')
    );
  }

  private handleLoginOrSignUpPage() {
    this.navigationMinimize = true;
    Session.valid = false;
    const showVideo = localStorage.getItem('showVideo');
    localStorage.clear();
    if (showVideo) localStorage.setItem('showVideo', showVideo);
  }

  private selectModelItem(uiElement: UIElement, route: string) {
    let selected = false;
    if (`/${uiElement.route}` != route && route.indexOf(uiElement.route) > -1) {
      uiElement.select();
    }
    if (`/${uiElement.route}` == route) {
      selected = true;
      uiElement.select();
    } else {
      if (uiElement instanceof Menu)
        uiElement.menuItems.values.forEach((mi) => {
          if (!selected) selected = this.selectModelItem(mi, route);
        });
      else if (uiElement instanceof MenuItem)
        uiElement.pages.values.forEach((p) => {
          if (!selected) selected = this.selectModelItem(p, route);
        });
      else if (uiElement instanceof Page) {
        uiElement.pages.values.forEach((p) => {
          if (!selected) selected = this.selectModelItem(p, route);
        });

        uiElement.tabs.values.forEach((t) => {
          if (!selected) selected = this.selectModelItem(t, route);
        });
      }
    }
    return selected;
  }

  private manageTabs() {
    if (!this.tabs) this.tabs = [];
    let tabId;
    if (sessionStorage.getItem('tabId'))
      tabId = sessionStorage.getItem('tabId');
    else {
      tabId = Guid.newGuid().toString();
      sessionStorage.setItem('tabId', tabId);
    }

    if (!this.tabs.some((t) => t == sessionStorage.getItem('tabId'))) {
      let tabs = this.tabs;
      tabs.push(tabId);
      this.tabs = tabs;
    }
  }

  setTitle() {
    if (environment.portalCode == 'PDP') this.title.setTitle('PayDayPortal');
    else if (environment.portalCode == 'PAP')
      this.title.setTitle('Payday admin portal');
  }

  copyAccountID() {
    navigator.clipboard.writeText(Session.accountID).then(
      () => {},
      () => {}
    );
  }

  changeVideoVisibility(e) {
    localStorage.setItem('showVideo', e);
    Session.showVideo$.next(e);
  }

  //#endregion

  ngOnDestroy(): void {
    this.broadcastService.disconnect();
    this.signalRConnected$?.unsubscribe();
  }

  openChatGPT() {
    this.showChatGPT = true;
    this.showChatMenu = false;
  }

  openChatMenu() {
    this.showChatMenu = true;
  }

  sendChatGPT() {
    var message = this.chatGptPrompt.nativeElement.value;
    this.chatGptPrompt.nativeElement.value = '';
    this.chatGptPrompt.nativeElement.disabled = true;
    if (message != null && message != '') {
      var model = {
        Messages:
          this.chatGPTResponse == null ? [] : this.chatGPTResponse.Messages,
        Prompt: message,
      };
      this.Session.suppressSpinner = true;
      this.showChatGPTSpinner = true;
      this.securityClient
        .sendChatGPT(model)
        .then((response) => {
          this.chatGptPrompt.nativeElement.disabled = false;
          this.Session.suppressSpinner = false;
          this.chatGptPrompt.nativeElement.value = '';
          this.chatGPTMessages = [];
          this.chatGPTResponse = response?.result;
          response?.result?.Messages?.forEach((x) => {
            if (x.Role == 'assistant' || x.Role == 'user') {
              var message = {
                Role: x.Role,
                Content: x.Content,
                Sent: this.datePipe.transform(x.Date, 'HH:mm'),
              };
              this.chatGPTMessages.push(message);
            }
          });
          setTimeout(() => {
            this.chatGPTHistory.nativeElement.scrollTop =
              this.chatGPTHistory.nativeElement.scrollHeight;
          }, 1);
          setTimeout(() => this.chatGptPrompt.nativeElement.focus(), 1);
          this.showChatGPTSpinner = false;
        })
        .catch((reason) => {
          this.chatGptPrompt.nativeElement.disabled = false;
          this.Session.suppressSpinner = false;
          this.showChatGPTSpinner = false;
        });
    }
  }

  closeChat() {
    this.showChatMenu = false;
    this.showChatGPT = false;
  }
}
