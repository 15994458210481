import { SecurityClient } from '../client/security.client';
import { Session, Menu } from '../../../common/entity/entities';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

export class SecurityHelper {
  public static getMenu(
    securityClient: SecurityClient,
    router: Router,
    message?: string
  ) {
    return new Promise<any>((resolve, reject) => {
      securityClient.menu(message, SecurityHelper.getMenuType()).then(
        (data) => {
          let response = data?.result;
          Menu.create(response);
          // Session.menu = new Menu(null, response);
          // Session.createModel(Session.menu, response.sort((a, b) => a.OrderNumber && a.OrderNumber - b.OrderNumber), null);
          Session.valid = true;

          let existsRoute = false;
          if (Session.requestedRoute) {
            let segments = Session.requestedRoute.split('/').slice(1);
            let length = segments.length;
            let valid = true;
            let lastItem = null;
            for (let i = 0; i < length; i++) {
              if (i == 0) {
                lastItem = Session.menu.menuItems.values.find(
                  (p) => p.data.Route == segments[i]
                );
                if (!lastItem) {
                  valid = false;
                  break;
                }
              } else if (i == 1) {
                lastItem = lastItem.pages.values.find(
                  (p) => p.data.Route == segments[i]
                );
                if (!lastItem) {
                  valid = false;
                  break;
                }
              } else if (i == 2) {
                lastItem = lastItem.tabs.values.find(
                  (p) => p.data.Route == segments[i]
                );
                if (!lastItem) {
                  valid = false;
                  break;
                }
              }
            }

            if (!valid) Session.requestedRoute = null;
          }

          let requesteRoute =
            Session.requestedRoute &&
            Session.requestedRoute.indexOf('login') == -1
              ? Session.requestedRoute
              : '/home';
          router.config
            .filter((c) => c.path != '')
            .forEach((c) => {
              if (!existsRoute) {
                if (requesteRoute.indexOf(c.path) != -1) existsRoute = true;
              }
            });

          existsRoute = false; //PREVENT NAVIGATING TO LAST ROUTE. ALWAYS NAVIGATE TO HOME/LANDING PAGE

          router.navigate([existsRoute ? requesteRoute : '/home']);

          if (router.config.some((x) => x.path == 'home'))
            router.config.find((x) => x.path == 'home').redirectTo =
              Session.landingPage;

          resolve(null);
        },
        (r) => {
          reject();
        }
      );
    });
  }

  public static rebindSession(model) {
    Session.rebindModel(model);
  }

  public static getActiveServiceStatusMessage(
    securityClient: SecurityClient,
    type: number
  ) {
    return new Promise<any>((resolve, reject) => {
      securityClient.getActiveServiceStatusMessage(Session.userCode, type).then(
      (data) => {
        resolve(data);
      },
      (r) => {
        reject();
      }
    );
    });
  }

  public static getMenuType() {
    return environment.portalCode == 'PDP' ? 1 : undefined;
  }
}
